import { useState } from 'react';
import styled from 'styled-components';

export const SideBarItem = ({ children, active }) => {


    return (
        <StyledSideBarItem active={active} >
            {children}
        </StyledSideBarItem>
    );
};

const StyledSideBarItem = styled.div` 

    transition: border-color .5s ease;
    padding: .4rem;

    display: flex;
    flex-direction: column;
    align-items: center;    

    p{
    font-size: .7rem;
    font-weight: bold;
    color: ${props => (props.active ? '#1a9bd7ff' : 'hsla(0,0%,100%,.5)')};
}

    /* border-bottom: ${props => (props.active ? '3px solid #1a9bd7ff' : '2px solid hsla(0,0%,100%,.15)')}; */

    a{
        color: ${props => (props.active ? '#1a9bd7ff' : 'hsla(0,0%,100%,.5)')};
        font-size: 1.5rem;
        display: flex;  
    }

`;