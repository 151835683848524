import styled from 'styled-components';
import { useEffect, useContext, useState, useRef } from 'react';

import { GlobalContext } from '../context/GlobalContext';
import { ConjugationContext } from '../features/Conjugation/context/ConjugationContext';
import { TopBar } from '../components/Nav/TopBar';
import { AppBar } from '../components/Nav/AppBar';

import { Verb } from '../features/Conjugation/components/Verb';
import { BoardConj } from '../features/Conjugation/components/BoardConj';
import { BoardDnd } from '../features/Conjugation/components/BoardDnd';
import { LangToggle } from '../components/LangToggle';
import { DragnDrop } from '../features/Conjugation/components/DragnDrop';
import regularVerb from '../features/Conjugation/assets/verbRegular.json';

import { IoMdArrowRoundBack } from 'react-icons/io';
import { SideBar } from '../components/Nav/SideBar';
import { AddButton } from '../components/Nav/AddButton';
import { IoPlayForwardOutline } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";

export const Conjugation = () => {

  const {
    setActiveApp,
    selectedLang
  } = useContext(GlobalContext);

  const {
    searchRandomTense,
    setIndexList,
    cheat,
    setCheat,
    verbs,
    verb,
    setVerb,
    tenses,
    TensesList,
    indexList
  } = useContext(ConjugationContext);

  const [play, setPlay] = useState(false);
  const [regVerb, setRegVerb] = useState(regularVerb);
  const [dat, setDat] = useState('');

  const playHandler = (e) => {
    setPlay(true);
    setVerb(verbs[e.target.id]);
    setCheat(true);
    setIndexList([]);
  };

  const regVerbHandler = (e) => {
    setPlay(true);
    setVerb(regVerb[e.target.id]);
    setCheat(true);
    setIndexList([]);
  };

  const cheatHandler = () => {
    setCheat(!cheat);
    if (cheat) {
      searchRandomTense();
    } else {
      setIndexList([]);
    }
  };

  const backHandler = () => {
    setPlay(false);
  };

  useEffect(() => {
    setActiveApp('conjugation');
    // const date = new Date("2024-02-28 15:11:27.058117-05");
    const date = new Date("2024-02-29T21:12:06.741Z").toLocaleString('en', { month: 'long' });

    setDat(date);

  }, []);

  return (
    <StyledConjugation practice={play}>
      <TopBar />
      <AppBar />
      <SideBar />
      <AddButton />

      <div className="conjContainer">

        <div className="struct">
          <div className="icons">
            <div className="icon" onClick={cheatHandler}>
              {!cheat ? <FaRegEye /> : <i><IoPlayForwardOutline /></i>}
            </div>
            {/* <p className='icon' onClick={cheatHandler}>{!cheat ? '👀' : '🤓 -next'}</p> */}
          </div>

          {!cheat ?
            <div className="dndContainer">
              <i className='backIcon' onClick={backHandler}><IoMdArrowRoundBack /></i>
              <DragnDrop />
            </div>
            :
            <div className="boardConjContainer">
              <i className='backIcon' onClick={backHandler}><IoMdArrowRoundBack /></i>
              <BoardConj />
            </div>
          }
        </div>

        <div className="verbList">
          <div className="verbBox">
            <div className="regularVerb">
              <h3>Regular</h3>
              {regVerb.map((verb, index) => {
                return <div key={index}>
                  <p className='availableVerb' onClick={regVerbHandler} id={index}>{regVerb[index].conjugation.infinitive_present[selectedLang]}</p>
                </div>;
              })}
              <p className='disabled'>to pray</p>
            </div>

            <div className="irregularVerb">
              <h3>Irregular</h3>
              {verbs.map((verb, index) => {
                return <div key={index}>
                  <p className='availableVerb' onClick={playHandler} id={index}>{verb.conjugation.infinitive_present[selectedLang]}</p>
                </div>;
              })}
              <p className='disabled'>to go</p>
              <p className='disabled'>to know</p>
            </div>
          </div>
        </div>

      </div>
    </StyledConjugation>
  );
};

const StyledConjugation = styled.div`      

      /* background: hsla(129, 61%, 39%, 0.2); */

      display: flex;
      flex-direction: column;
      align-items: center;

      .conjContainer{
        /* position: relative; */
        display: grid;
        grid-template-areas: 'conjugation';
      }

      .verbList{
        z-index: 5;
        /* background: hsla(129, 61%, 39%, 0.2); */
        background: var(--bgColor);
        grid-area: conjugation;
        /* display: ${props => (props.practice ? 'none' : 'flex')}; */
        opacity: ${props => (props.practice ? '0' : '1')};
        width: 100%;
        padding: 1rem;
        transition: opacity 1.5s ease-out .1s;
        
        flex-direction: column;
      }
      
      .verbBox{

        /* background: hsla(129, 61%, 39%, 0.2); */
        display: flex;
        gap: 1rem;
        
        justify-content: space-around;
        font-size: 1.2rem;
        
        h3{
          color: var(--disabled);
          font-weight: normal;
          padding-bottom: 1rem;
          text-decoration: underline;
        }
        
      }
      
      .availableVerb{
        font-weight: bold;

        :hover{
          cursor: pointer;
          color: var(--color5);
        }
      }
      .dndContainer{
        width: 100%;
        padding: .4rem;
      }

      .struct{
        background: var(--bgColor);
        grid-area: conjugation;
        z-index: 10;
        width: ${props => (props.practice ? '100%' : '0%')};
        opacity: ${props => (props.practice ? '1' : '0')};
        transition: width 1s ease;
        overflow: hidden;
      }   
      
      .boardConjContainer{
        display: flex;
        flex-direction: column;
      }      
      
      .icons{
        position: absolute;
        top: 4rem;
        right: 3rem;
        font-size: 1.5rem;
        align-self: flex-start;
        padding-left: .5rem;
        display: flex;

        :hover{
          cursor: pointer;
        }
      }  

      .backIcon{
        padding-top: .5rem;
        font-size: 1.2rem;
      }

      .disabled{
        font-weight: bold;
        color: var(--disabled);
        :hover{
          color: var(--disabled);

        }
      }
       

`;
