import styled from 'styled-components';
import { useContext } from 'react';
import { ConjugationContext } from '../context/ConjugationContext';
import { useEffect } from 'react';
import { Tense } from './Tense';
import { Verb } from './Verb';
import { GlobalContext } from '../../../context/GlobalContext';

export const BoardDnd = () => {

   const {
      selectedLang
   } = useContext(GlobalContext);

   const {
      verb,
      tenses,
      TensesList
   } = useContext(ConjugationContext);   
  
   return (
      <StyledBoardDnd>
         
         <div className="dndBoardContainer">
            <div className="base">
               <div className="infinitive">
                  <h4>Infinitive</h4>
                  <Tense title={TensesList.INFINITIVE} />
               </div>

               <div className="participle">
                  <h4>Participle</h4>
                  <div className='mode'>
                     <Tense title={TensesList.PART_PRESENT} pholder='present'/>
                     <Tense title={TensesList.PART_PAST} pholder='past'/>
                  </div>
               </div>

               <div className="imperative">
                  <h4>Imperative</h4>
                  <Tense title={TensesList.IMPERATIVE}/ >
               </div>
            </div>

            <div className="simple">
               <h4>Simple</h4>
               <div className="tenseGroup">
                  <Tense title={TensesList.SIMPLE_PRESENT} pholder='present' />
                  <Tense title={TensesList.SIMPLE_PAST} pholder='past' />
                  <Tense title={TensesList.SIMPLE_FUTURE} pholder='future' />
                  <Tense title={TensesList.SIMPLE_CONDITIONAL} pholder='conditional'/>
               </div>
            </div>

            <div className="perfect">
               <h4>Perfect</h4>
               <div className="tenseGroup">
                  <Tense title={TensesList.PERFECT_PRESENT} pholder='present'/>
                  <Tense title={TensesList.PERFECT_PAST} pholder='past'/>
                  <Tense title={TensesList.PERFECT_FUTURE} pholder='future'/>
                  <Tense title={TensesList.PERFECT_CONDITIONAL} pholder='conditional'/>
               </div>
            </div>
         </div>
         
      </StyledBoardDnd>
   );
};

const StyledBoardDnd = styled.div`
    
    /* background: hsla(98, 100%, 80%, 0.5); */
       
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .dndBoardContainer{
       display: flex;
       justify-content: center;
       padding: .5rem;
       gap: .9rem;
       padding-left: 0;
    }

    h4{
      padding-left: .3rem;
      padding-bottom: .3em;
    }    

    .tenseGroup{
      
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
    }

    .base{
      padding-right: 1rem;
      border-right: 1px solid var(--fontColor);
    }


`;