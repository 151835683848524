import styled from 'styled-components';
import { GlobalContext } from '../context/GlobalContext';
import { useContext, useEffect, useRef } from 'react';

export const AddAlert = () => {

    const { added, refAlert } = useContext(GlobalContext); 


    return (
        <StyledAddAlert added={false}>
            <div className="addAlert" ref={refAlert}>
                <p> <span>+ 1</span>  👍</p>
            </div>
        </StyledAddAlert>
    );
};

const StyledAddAlert = styled.div`
    

    .addAlert{
    width: 100%;

    /* background: hsla(0,0%,100%,.1); */
    opacity: ${props => (props.added ? '1' : '0')};
    height: ${props => (props.added ? '4rem' : '0rem')};
    
    transition: 1s ease-in-out;

    span{
        color: var(--color5);
        font-size: 1.5rem;
    }

}

`;