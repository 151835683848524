import { useState } from 'react';
import styled from 'styled-components';

export const AppBarItem = ({ children, active }) => {


    return (
        <StyledAppBarItem active={active} >
            {children}
        </StyledAppBarItem>
    );
};

const StyledAppBarItem = styled.div` 

/* background-color: crimson; */
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;


color: ${props => (props.active ? '#1a9bd7ff' : 'hsla(0,0%,100%,.2)')};
font-size: 1.6rem;

transition: border-color .5s ease;
padding-top: .5rem;
border-top: ${props => (props.active ? '3px solid #1a9bd7ff' : '2px solid hsla(0,0%,100%,.15)')};

p{    
    font-size: ${props => (props.active ? '1rem' : '.8rem')};    
    color: ${props => (props.active ? '#1a9bd7ff' : 'hsla(0,0%,100%,.2)')};    
    font-weight: ${props => (props.active ? '900' : 'hsla(0,0%,100%,.2)')};    
    /* display: ${props => (props.active ? 'flex' : 'none')};     */
}
      

`;