import styled from 'styled-components';
import { useState } from 'react';
import { CiEdit } from "react-icons/ci";
import { IoTrashBinOutline } from "react-icons/io5";


export const Phrase = ({ info, index }) => {

    const [active, setActive] = useState(false);

    const activeHandler = () => {
        setActive(!active);
    };

    return (
        <StyledPhrase active={active}>
            <div className="phrase" >
                <div className="content" onClick={activeHandler}>
                    <p className='number'>{index + 1}.</p>
                    <p className='sentence'>{info && info.phrase}</p>
                </div>
                {/* <i className='editPhrase'><CiEdit /></i> */}
            </div>
            <div className="hidden">
                <div className="hidden_content">
                    <p></p>
                </div>
                <div className="phrase_options">
                    <div className="options option_del">
                        <i> <IoTrashBinOutline />
                        </i>
                        {/* <p>Delete</p> */}
                    </div>
                    <div className="options option_modif">
                        <i> <CiEdit />
                        </i>
                        {/* <p>Modify</p> */}
                    </div>


                    <input type="checkbox" name="phrase_check" id="" />
                </div>
            </div>
        </StyledPhrase>
    );
};

const StyledPhrase = styled.div`

    /* background-color: crimson; */
    /* padding: .4rem 0rem; */
    display: flex;
    flex-direction: column;
    gap: .4rem;

    height: ${props => (props.active ? '10rem' : '2rem')};
    overflow: hidden;

    transition: height .4s ease;

    .phrase{
        display: flex;
        gap: 3rem;
        justify-content: space-between;       
    }    

    .content{
        display: flex;
        gap: .4rem;
        padding: .2rem .5rem;
        border-radius: .3rem;
        
        /* background: var(--color5);   */

        :hover{

            cursor: pointer;
            background: var(--color5);                      
        }        
    }

    .hidden{
        display: flex;
        justify-content: space-around;
        /* gap: 1rem; */
        /* flex-direction: column; */
        background-color: hsla(0,0%,100%,.1);
        /* background: crimson; */
        height: 100%;
        border-radius: 0rem 0rem .5rem .5rem; 
        justify-content: space-between;
        /* padding: .5rem .5rem .5rem .5rem; */
        overflow: hidden;
    }
    
    .hidden_content{
        width: 100%;
        padding: .5rem;
        /* background: greenyellow; */
        
    }
    
    
    .phrase_options{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        background-color: hsla(0,0%,100%,.1);
        gap: .5rem;
        padding: .5rem 0rem;
       
        
        i{
            font-size: 1.1rem;
            display: flex;
            justify-content: center;
            
            padding: .5rem .5rem .5rem .5rem;
            
            :hover{
                /* color: var(--color5); */
                cursor: pointer;
            }
        } 
        
        
    }
    
    .options{
        display: flex;
        align-items: center ;
        /* gap: .5rem; */
        
        i{
            color: var(--color5);
            
        }

        /* background-color: hsla(0,0%,100%,.1); */
        /* padding: .2rem; */
        border-radius: .5rem;

        :hover{
            cursor: pointer;
        background-color: hsla(0,0%,100%,.1);

                /* border: 1px solid var(--fontColor); */

            }
    }

.option_del{
    i{
        color: crimson;
    }
}
.option_modif{

    i{
        /* color: yellowgreen ; */
        font-size: 1.3rem;
    }
}
    
    .number{
        color: var(--disabled);
    }
    
    .editPhrase{
        font-size: 1.5rem;
        color: var(--disabled);
        
        :hover{
            cursor: pointer;
            /* color: var(--color5) */
        }
    }
`;