
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyAEK4P9E9WW2RSugE02rPDCKhq5gxlf39Y",
    authDomain: "qbelex.firebaseapp.com",
    projectId: "qbelex",
    storageBucket: "qbelex.appspot.com",
    messagingSenderId: "261930273389",
    appId: "1:261930273389:web:46ca0b1535d1621fe7cd64",
    measurementId: "G-4PFP6YBD1R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);