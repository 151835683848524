import styled from 'styled-components';
import { useContext, useEffect, useState, useRef } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { PhrasesContext } from '../features/Phrases/context/PhrasesContext';
import { authContext } from '../features/Authentication/context/authContext';
import { UserContext } from '../context/UserContext';

import { TopBar } from '../components/Nav/TopBar';
import { Phrase } from '../features/Phrases/components/Phrase';
import { LangToggle } from '../components/LangToggle';
import { AppBar } from '../components/Nav/AppBar';
import { UserPhrases } from '../features/Phrases/components/UserPhrases';
import { Modal } from '../components/Modal';
import { EditForm } from '../features/Phrases/components/EditForm';
import { SideBar } from '../components/Nav/SideBar';
import { TbFolders } from 'react-icons/tb';
import { AddButton } from '../components/Nav/AddButton';
import { BiSend } from 'react-icons/bi';
import { AddForm } from '../features/Phrases/components/AddForm';
import { Folder } from '../features/Phrases/components/Folder';
import { FcTodoList } from "react-icons/fc";
import { RxUpdate } from "react-icons/rx";


export const Phrases = () => {

    const {
        setActiveApp
    } = useContext(GlobalContext);

    const { user } = useContext(authContext);

    const {
        userPhrases,
        setUserPhrases,
        getUserPhrases
    } = useContext(PhrasesContext);


    const [chargePhrases, setChargePhrases] = useState(false);

    const chargeHandler = () => {
        setChargePhrases(!chargePhrases);
        getUserPhrases(user);
        console.log(chargePhrases);
    };

    useEffect(() => {
        setActiveApp('phrases');
    }, []);

    return (
        <StyledPhrases charge={chargePhrases}>
            <TopBar />
            <SideBar />
            <AddButton />
            <AppBar />

            

            <div className="phrasesContainer">
                <div className="phrasesTitle">
                    <i><FcTodoList /></i>
                    <p>Phrases</p>
                </div>
                
                <div className="phrasesList">
                    
                    {userPhrases && userPhrases.map((item, index) => {
                        return < Phrase info={item} index={index} />
                    })}
                    
                </div>
                <i onClick={chargeHandler} className="updatePhrases" ><RxUpdate /></i>
            </div>

        </StyledPhrases>
    );
};

const StyledPhrases = styled.div`

    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15rem;    
    
    .phrasesContainer{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;        
    }

    .updatePhrases{
        display: flex;
        /* background-color: green; */
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 1.5rem;
        transform: rotate(${props => (props.charge ? '405deg' : '0deg')});        
        transition: all 1s ease;   

        :hover{
            cursor: pointer;
            color: var(--color5);
        }
    }

    .phrasesTitle{
        display: flex;
        align-items: center;
        padding: 1rem;
        gap: .5rem;
        font-size: 1.5rem;

        i{
            font-size: 1.5rem;
            display: flex;
        }
    }

    .phrasesList{
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
    
`;