import { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../../../context/GlobalContext';

export const AbstractLogin = () => {

    const { modal, setModal } = useContext(GlobalContext);

    const modalHandler = () => {
        setModal(!modal);
    };

    return (
        <StyledAbstract>

            <div className="loginBtn" onClick={modalHandler}></div>

            <div className="abstract-container">
                <div className="item"></div>
                <div className="item"></div>
                <div className="item">
                    <div className="strip"></div>
                    <div className="strip"></div>
                    <div className="strip"></div>
                    <div className="strip"></div>
                    <div className="strip"></div>
                </div>
                <div className="item">
                    <div className="inner-circle">
                        <div className="core"></div>
                    </div>
                </div>
                <div className="item">
                    <div className="inner">
                        <div className="center"></div>
                    </div>
                </div>
                <div className="item"></div>
            </div>

        </StyledAbstract>
    );
};


const StyledAbstract = styled.div`

background: black;
margin: 1rem;

border-radius: 1rem;
position: relative;

display: flex;
overflow: hidden;

max-width: 33rem;

.loginBtn{
    width: 2rem;
    height: 2rem;
    background: firebrick;
    
    border-radius: 50%;
    position: absolute;
        top: 1rem;
        left: 8rem;
}

.abstract-container{
    display: grid;
    grid-template-columns: minmax(20rem, 30rem);
    
    margin: 1rem;

}


.item{
    width: 8rem;
    height: 8rem;
   
    border-radius: 50%;
    position: absolute;

    &:nth-child(1){
        background: pink;
        top: -1rem;
        left: -5rem;
    }
    
    &:nth-child(2){
        background: skyblue;
        top: 50%;
        left: 50%;
    }
    &:nth-child(6){
        background: transparent;
        top: 80%;
        left: 80%;
        border: .2rem solid darkorange;
    }
    
    &:nth-child(5){
        background: skyblue;
        width: 12rem;
        height: 12rem;
        top: 10rem;
        left: -8%;

        position: relative;

        .inner{
            
            position: absolute;
            background: hsla(0, 0%, 100%, 0.397);
            width: 10rem;
            height: 10rem;
            top: 1rem;
            left: 50%;
            transform: translate(-50%, 0);

            border-radius: 50%;
        }

        .center{

            width: 6rem;
            height: 6rem;
            background: skyblue;
            position: absolute;

            top: 2rem;
            left: 50%;
            transform: translate(-50%, 0);
            border-radius: 50%;
            
        }


    }

    &:nth-child(4){
        background: khaki;
        top: 10%;
        left: 85%;
        width: 10rem;
        height: 10rem;


        .inner-circle{
            width: 5rem;
            height: 5rem;

            background: lightsalmon;
            border-radius: 1rem 0rem 0 5rem ;
        }
        
        .core{
            position: absolute;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            background: lightseagreen;
            top: -4rem;
            left: 2rem;

        }

    }

    &:nth-child(3){
        background: yellowgreen;
        top: 5rem;
        left: 10rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow: hidden;

        transform: rotate(-40deg);

        .strip{
            width: 100%;
            height: 1rem;
            background: hsla(0,0%, 100%, 0.5);

        }
    }
}



  
`;