import styled from 'styled-components';

export const Collapsible = ({ title, icon, number, children }) => {


    return (
        <StyledCollapsible
        // className='noSelect'
        >
            <details>
                <summary>
                    <div className="sumheader">
                        <p>{title} </p>
                        <div className="right">
                            <p>{icon}</p>
                            {number ? <p>{number}</p> : ''}
                        </div>
                    </div>
                </summary>
                <div className="content noScrollBar">
                    {children}
                </div>
            </details>
        </StyledCollapsible>
    );
};

const StyledCollapsible = styled.div`


 /* background-color: hsla(0,0%,100%, .1); */
 max-width: 30rem;
/* padding-left: 1rem; */
    
    details > summary {
    /* list-style-type: '▶️'; */
    list-style-type: '';
    padding: .5rem;
    border-radius: .5rem;

    :hover{
        cursor: pointer;
        background-color: var(--color5);
        /* opacity: .1; */
    }
    
    border: 1px solid var(--fontColor);
}

.sumheader{
    /* background-color: crimson; */
    min-width: 10rem;

    display: flex;
    justify-content: space-between;
    padding-right: .5rem;

    .right{
        display: flex;
        gap: .3rem;
    }

}

.content{
    /* background-color: var(--color3); */
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 20rem;
    overflow-y: scroll;
  
}

`;