import styled from 'styled-components';

import { TopBar } from '../components/Nav/TopBar';
import { AppBar } from '../components/Nav/AppBar';
import { GlobalContext } from '../context/GlobalContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { SideBar } from '../components/Nav/SideBar';

export const Plus = () => {

    const {
        setActiveApp
    } = useContext(GlobalContext);

    useEffect(() => {
        setActiveApp('plus');

    }, []);

    return (
        <StyledPlus>
            <TopBar />
            <AppBar />
            <SideBar />

            
            <div className="plusContainer">
                <p>... si quiere más que le piquen caña !</p>
                <p>just kidding 😜</p>
                <p>pronto podré ofrecerte ➕ fun learning</p>
            </div>
        </StyledPlus>
    );
};

const StyledPlus = styled.div`

width: 100%;
height: 100%;

display: flex;
flex-direction: column;
justify-content: start;
align-items: center;

.plusContainer{
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

}


`;
