import styled from 'styled-components';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { Phrase } from './OldPhrase';
import { PhrasesContext } from '../context/PhrasesContext';
import { useState } from 'react';
import { PhrasesCounter } from './PhrasesCounter';

export const UserPhrases = () => {

    const {
        userPhrases,
        setUserPhrases,
        getUserPhrases,
    } = useContext(UserContext);

    const {
        activeFilter,
        setActiveFilter,
        filteredPhrases,
    } = useContext(PhrasesContext);

    const [tagFilter, setTagFilter] = useState(0);
    const [updt, setUpdt] = useState(false);

    function filteredHandler() {

        let filterNumber = 0;
        filteredPhrases.map(phrase => {
            if (phrase) filterNumber += 1;
        });
        setTagFilter(filterNumber);
    };


    return (
        <StyledUserPhrases>

            <div className="phraseSection">

                <div className="numberContainer">
                    <PhrasesCounter tagFilter={tagFilter} />
                </div>

                {activeFilter ?
                    filteredPhrases.map((phrase, index) => {

                        if (!phrase) return;

                        return <Phrase
                            key={phrase.id}
                            data={phrase}
                            index={index + 1}
                        />;
                    })
                    :
                    userPhrases && userPhrases.map((phrase, index) => {
                        return <Phrase
                            key={phrase.id}
                            data={phrase}
                            index={index + 1}
                        />;
                    })
                }

            </div>
        </StyledUserPhrases>
    );
};

const StyledUserPhrases = styled.div`    
    
    display: flex;
    
    .phraseSection{
        width: 100%;
        
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
`;