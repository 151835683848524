import styled from 'styled-components';
import { TbListDetails } from 'react-icons/tb';
import { RiLayout2Line } from 'react-icons/ri';
import { VscGraph } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import { AppBarItem } from '../AppBarItem';
import { GlobalContext } from '../../context/GlobalContext';
import { useContext, useState } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import { AddForm } from '../../features/Phrases/components/AddForm';
import { AddAlert } from '../AddAlert';

export const AppBar = ({ children }) => {

    const {
        activeApp,
        showPhraseForm
    } = useContext(GlobalContext);


    function activeHandler(name) {
        let active = activeApp === name ? true : false;
        return active;
    }

    return (
        <StyledAppBar className='noSelect' showForm={showPhraseForm} >

            <div className="addedAlert">
                <AddAlert />
            </div>

            <div className="optionsContainer">
                <div className="optionsContent">
                    <i className='arrowUp'><IoIosArrowUp /></i>
                    <AddForm />
                </div>
            </div>

            <div className="appBarContainer noSelect">
                <Link to={'/phrases'}>
                    <AppBarItem active={activeHandler('phrases')} >
                        <TbListDetails />
                        <p>Phrases</p>
                    </AppBarItem>
                </Link>

                <Link to={'/conjugation'}>
                    <AppBarItem active={activeHandler('conjugation')} >
                        <RiLayout2Line />
                        <p>Verbs</p>
                    </AppBarItem>
                </Link>

                <Link to={'/stats'}>
                    <AppBarItem active={activeHandler('stats')} >
                        <VscGraph />
                        <p>Stats</p>
                    </AppBarItem>
                </Link>

                <Link to='#'>
                </Link>
            </div>

        </StyledAppBar>
    );
};

const StyledAppBar = styled.div`   

    position: fixed; 
    
    bottom: 0; 
    left: 0;
    z-index: 500; 

    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column; 
    /* justify-content: end ; */

    /* background: hsla(168, 84%, 69%, 0.137); */
    
    .appBarContainer{        
        background: var(--color3); 
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: start ;
        padding-bottom: .5rem;         
        
        > a{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;       
        }
    }

    .optionsContainer{
        background: var(--bgColor);
        /* background: red; */
        width: 100%;
        max-width: 30rem;
        min-width: 20rem;
        display: flex;
        flex-direction: column;
        align-self: center;
        
        height: ${props => (props.showForm ? '12rem' : '0rem')};        
        overflow: hidden;
        transition: all .5s ease-in-out;
        box-shadow: 0rem 0rem 1rem hsla(0, 0%, 0%, 0.5);
    }

    .optionsContent{
        display: flex;
        flex-direction: column;
        padding: .1rem 1rem;
    }
    
    .arrowUp{
        align-self: center;
        font-size: 1.5rem;
        color: var(--disabled);

        :hover{
            cursor: pointer;
            color: var(--color5);
        }
    }

    .addedAlert{
        display: flex;
        justify-content: center;
    }

    @media all and (min-width: 630px)  {
    
    /* display: none; */

    .optionsContainer{

       } 

}
    
`;