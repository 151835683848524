import { createContext, useEffect, useState } from 'react';
import { GlobalStyles } from '../components/GlobalStyles';
import { useLocalStorage } from '../hooks/useLocalStorage';

export const UserContext = createContext();

export function UserProvider({ children }) {

    const location = 'https://qbelex-api-bypa6cepfa-uc.a.run.app';
    //const location = 'http://localhost:5000';

    const [userPhrases, setUserPhrases] = useState(null);

    const [modal, setModal] = useState(false);
    const [theme, setTheme] = useState(true);
    const [language, setLanguage] = useState('es');
    const [foreignLang, setForeignLang] = useState('en');
    const [langToggle, setLangToggle] = useState(false);
    const [selectedLang, setSelectedLang] = useState('en');

    const [fbusers, setFbusers] = useLocalStorage('fbusers', '');
    const [pgusers, setPgusers] = useLocalStorage('pgusers', '');
    const [pguserInfo, setPguserInfo] = useLocalStorage('pguser', '');

    const [topics, setTopics] = useLocalStorage('topics', '');
    const [tenses, setTenses] = useLocalStorage('tenses', '');
    const [verbs, setVerbs] = useLocalStorage('verbs', '');
    const [phrases, setPhrases] = useLocalStorage('phrases', '');


    // const [tagList, setTagList] = useState(['']);
    // const [selectedTag, setSelectedTag] = useState(null);
    // const [phrasesByTag, setPhrasesByTag] = useState(null);

    // function filterByDate(array) {

    //     return (
    //         array.sort((x, y) => {
    //             return new Date(x.created) < new Date(y.created) ? 1 : -1;
    //         })
    //     );

    //     // return (
    //     //     array.sort((x, y) => {
    //     //         return new Date(x.created) < new Date(y.created) ? 1 : -1;
    //     //     })
    //     // ).reverse();
    // }

    // const getUserPhrases = async (user, lang) => {

    //     const userId = user.uid;

    //     try {
    //         const res = await fetch(`${location}/data/${userId}/${lang}`);
    //         const data = await res.json();
    //         filterByDate(data);
    //         setUserPhrases(data);
    //         tagHandler(data);

    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const addPhrase = async (info) => {

    //     const { userId, lang, phrase } = info;
    //     console.log(phrase);

    //     const settings = {
    //         method: 'POST',
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(phrase)
    //     };

    //     try {
    //         const Response = await fetch(`${location}/data/${userId}/${lang}`, settings);
    //         const data = JSON.stringify(Response);

    //     } catch (error) {
    //         console.log(error.message);
    //     }
    // };

    // function tagHandler(phrases) {
    //     let tags = [];

    //     phrases.map(phrase => {

    //         if (!phrase.tags) return;

    //         phrase.tags.map(tag => {
    //             if (tag == "") return;
    //             let included = tags.includes(tag);
    //             if (!included) {
    //                 tags.push(tag);
    //             }
    //         });
    //     });
    //     setTagList(tags);

    //     phrasesCounterTag(tags, phrases);
    // };

    // function phrasesCounterTag(tagList, phrases) {

    //     let obj = {};

    //     tagList.map(tag => {

    //         let count = 0;

    //         phrases.map(phrase => {
    //             if (phrase.tags.includes(tag)) count++;
    //         });

    //         obj = { ...obj, [tag]: count };

    //     });
    //     setPhrasesByTag(obj);
    // }

    const getUsersList = async () => {

        try {
            const res = await fetch(`${location}/fb-users`);
            const data = await res.json();
            setFbusers(data);
            // console.log(data);

        } catch (error) {
            console.log(error);
        }
    };

    const getPgUserInfo = async ({ uid }) => {

        try {
            const res = await fetch(`${location}/userInfo/${uid}`);
            const data = await res.json();
            // console.log(data);
            setPguserInfo(data);

        } catch (error) {
            console.log(error);
        }
    };

    const getPgUsers = async () => {

        try {
            const res = await fetch(`${location}/users`);
            const data = await res.json();
            setPgusers(data);
            // console.log(data);

        } catch (error) {
            console.log(error);
        }
    };

    const addPgUser = async (regUser) => {

        const { uid, email } = regUser;
        // const creationDate = new Date();
        const created = new Date().toISOString();

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid, email, created })
        };

        try {
            const Response = await fetch(`${location}/addpgUser/`, settings);
            const data = JSON.stringify(Response);
            // console.log(data);
            addClaim(regUser);

        } catch (error) {
            console.log(error.message);
        }

    };

    const addClaim = async ({ email }) => {

        const settings = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email })
        };

        try {
            const Response = await fetch(`${location}/addpgClaim/`, settings);
            const data = JSON.stringify(Response);
            // console.log(data)

        } catch (error) {
            console.log(error.message);
        }
    };

    const updatePgUser = async (regUser) => {

        const settings = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(regUser)
        };

        try {
            const Response = await fetch(`${location}/updateUser`, settings);
            const data = JSON.stringify(Response);
            setPguserInfo(data);
            // console.log(data);

        } catch (error) {
            console.log(error.message);
        }
    };

    const getTopics = async () => {

        try {
            const res = await fetch(`${location}/topics`);
            const data = await res.json();
            setTopics(data);
            // console.log(data);

        } catch (error) {
            console.log(error);
        }
    };

    const getTenses = async () => {

        try {
            const res = await fetch(`${location}/tenses`);
            const data = await res.json();
            setTenses(data);
            // console.log(data);

        } catch (error) {
            console.log(error);
        }
    };

    const getVerbs = async () => {

        try {
            const res = await fetch(`${location}/verbs`);
            const data = await res.json();
            setVerbs(data);
            // console.log(data);

        } catch (error) {
            console.log(error);
        }
    };

    const getPhrases = async () => {

        try {
            const res = await fetch(`${location}/phrases`);
            const data = await res.json();
            setPhrases(data);
            console.log(data);

        } catch (error) {
            console.log(error);
        }
    };

    const addPhrase = async (phrase) => {

        console.log(phrase);

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(phrase)
        };

        try {
            const Response = await fetch(`${location}/phrase/`, settings);
            const data = JSON.stringify(Response);
            console.log(data);

        } catch (error) {
            console.log(error.message);
        }

    };


    const value = {
        fbusers,
        setFbusers,
        pgusers,
        setPgusers,
        pguserInfo,
        setPguserInfo,

        topics,
        setTopics,
        tenses,
        setTenses,
        verbs,
        setVerbs,
        phrases,
        setPhrases,

        getUsersList,
        addPgUser,
        getPgUsers,
        getPgUserInfo,
        updatePgUser,
        getTopics,
        getTenses,
        getVerbs,
        getPhrases,

        addPhrase,

        language,
        setLanguage,
        foreignLang,
        setForeignLang,
        selectedLang,
        setSelectedLang,
        langToggle,
        setLangToggle,
        theme,
        setTheme,

        // userPhrases,
        // setUserPhrases,
        // getUserPhrases,
        // addPhrase,
        // tagList,
        // setTagList,
        // selectedTag,
        // setSelectedTag,
        // phrasesByTag
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}
