import { createContext, useEffect, useRef, useState } from 'react';
import { GlobalStyles } from '../components/GlobalStyles';
import { useLocalStorage } from '../hooks/useLocalStorage';

export const GlobalContext = createContext();

export function GlobalProvider({ children }) {

    const [modal, setModal] = useState(false);
    // const [theme, setTheme] = useState(true);
    const [theme, setTheme] = useLocalStorage('theme', '');

    const [language, setLanguage] = useState('es');
    const [foreignLang, setForeignLang] = useState('en');
    const [langToggle, setLangToggle] = useState(false);
    const [selectedLang, setSelectedLang] = useState('en');
    const [activeApp, setActiveApp] = useState('welcome');
    const [showPhraseForm, setShowPhraseForm] = useState(false);
    const [added, setAdded] = useState(false);

    const [isSideBarVisible, setIsSideBarVisible] = useState(true);
    const [isTopBarVisible, setIsTopBarVisible] = useState(true);

    const refAlert = useRef();
    const refTags = useRef();


    const value = {
        modal,
        setModal,
        language,
        setLanguage,
        foreignLang,
        setForeignLang,
        selectedLang,
        setSelectedLang,
        langToggle,
        setLangToggle,
        theme,
        setTheme,
        activeApp,
        setActiveApp,
        isSideBarVisible,
        setIsSideBarVisible,
        isTopBarVisible,
        setIsTopBarVisible,
        showPhraseForm,
        setShowPhraseForm,
        added,
        setAdded,
        refAlert,
        refTags
    };

    return (
        <GlobalContext.Provider value={value}>
            < GlobalStyles dark={theme} />
            {children}
        </GlobalContext.Provider>
    );
}
