import styled from 'styled-components';
import { FiEdit3 } from 'react-icons/fi';
import { GiMusicalNotes } from 'react-icons/gi';
import { BsTrash, BsCamera } from 'react-icons/bs';
import { authContext } from '../../Authentication/context/authContext';
import { PhrasesContext } from '../context/PhrasesContext';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { Modal } from '../../../components/Modal';
import { useRef } from 'react';
import { UserContext } from '../../../context/UserContext';

export const Phrase = ({ data, index }) => {

    const { user } = useContext(authContext);
    const { userPhrases, setUserPhrases, getUserPhrases } = useContext(UserContext);

    const {
        language,
        foreignLang,
        selectedLang,
        modal,
        setModal,
        showPhraseForm,
        setShowPhraseForm
    } = useContext(GlobalContext);

    const {
        updatePhrases,
        deletePhrase,
        selectedPhrase,
        setSelectedPhrase,
        edit,
        setEdit,
        updatePhrase,
        updateToggle,
        setUpdateToggle,
        refModifPhrase,
        refModifMeaning,
        refModifTags,
        refTags,
        selectedTag
    } = useContext(PhrasesContext);

    const refPhrase = useRef();
    const refContent = useRef();

    const [emoji, setEmoji] = useState('😲');
    const [phraseDate, setPhraseDate] = useState(null);
    const [more, setMore] = useState(false);
    const [today, setToday] = useState(null);

    const [foreignPhrase, setForeignPhrase] = useState(null);
    const [meanings, setMeanings] = useState(null);

    const selectHandler = () => {
        setEdit(false);
        setModal(!modal);
        setSelectedPhrase(data);
        setShowPhraseForm(false);
    };

    const updateHandler = () => {
        setEdit(true);
        setModal(!modal);
        setSelectedPhrase(data);
        setShowPhraseForm(false);

        if (data.en) {
            refModifPhrase.current.value = data.en[0];
            refModifMeaning.current.value = data.es[0];
            refModifTags.current.value = data.tags[0];
        } else {
            refModifPhrase.current.value = data.foreign;
            refModifMeaning.current.value = data.meaning[0];
        }

    };

    const todayHandler = () => {
        let today = Date.now();
        let todaysDate = new Date(today);
        let stringDate = todaysDate.toLocaleString('es-es', {
            month: 'short',
            day: 'numeric',
        });

        return stringDate;
    };

    const expandHandler = () => {
        setMore(!more);
        refContent.current.classList.toggle("expanded");
    };

    useEffect(() => {
        setToday(todayHandler);

        if (data.created) {
            let date = new Date(data.created);

            let time = date.toLocaleString('en-US', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
            });

            let info = date.toLocaleString('es-es', {
                month: 'short',
                day: 'numeric',
            });
            setPhraseDate(info);
        }

        if (data.tags) {

        }
    }, []);

    useEffect(() => {
        
    }, [selectedLang]);


    return (
        <StyledPhrase show={more} today={false}>

            <div className="phraseContainer" >
                <div className="phraseHeader" ref={refPhrase} id={data.id} onClick={expandHandler} >
                    <div >
                        <p className='foreign' >{!data.en && data.foreign}</p>
                        <p className='foreign' >{data[selectedLang] && data[selectedLang][0]}</p>
                    </div>
                    <div className="dateInfo noSelect">
                        {phraseDate && <p className='date'>{phraseDate == today ? 'hoy' : phraseDate}</p>}
                    </div>
                </div>

                <div className="phraseContent" >
                    <div className="phraseBox" ref={refContent}>
                        <p>{data.es &&
                            selectedLang == foreignLang ? data[language] : data[foreignLang]
                        }</p>
                        <p>{!data.es && data.meaning[0]}</p>
                    </div>
                </div>

                <div className="sectionEdit">
                    <span onClick={updateHandler}><FiEdit3 /></span>
                    <span onClick={selectHandler}><BsTrash /></span>
                </div>
            </div>

        </StyledPhrase >
    );
};

const StyledPhrase = styled.div`

    background: var(--clearBG);
    padding: .5rem .5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    
    border-radius: .5rem;
    overflow: hidden;    
    transition: all .5s ease-in-out;
    
    .phraseContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;  
        gap: .5rem ;
        transition: all .5s ease-in-out;   
    }
    
    .phraseHeader{     
        cursor: pointer;   
        display: flex;
        justify-content: space-between;
        transition: all .5s ease-in-out;
    }

    .dateInfo{
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-between;
        gap: 1rem;
        opacity: .5;
        p{
            width: 2.5rem;
            text-align: end;
        }
    }    
    
    .foreign{
        cursor: pointer;
        font-weight: bold;
    }

    .options{
        color: var(--disabled);
        display: flex;
        gap: 1rem;
    }    

    .date{
        align-self: flex-end;
        font-size: .8rem;
        opacity: .5;
    }

    .phraseContent {   
        width: 100% ;
        display: flex;
        flex-direction: column;    
    }

    .phraseBox{
        flex-basis: 0;
        overflow: hidden;
        transition: all .4s ease;

        p{       
            color: var(--color5);
            height: auto;
            word-wrap: break-word;
            opacity: ${props => (props.show ? '1' : '0')};  
            transition: opacity .5s ease-in-out .2s;
        }
        
    }

    .expanded{
        flex: 1;
    }        

    .sectionEdit{
        justify-self: end;
        
        display: ${props => (props.show ? 'flex' : 'none')}; 
       
        gap: 1rem;
        justify-content: end;
        opacity: .4;        

        span{

            :hover{
                cursor: pointer;
                color: var(--fontColor);
            }
        }
    }   

`;