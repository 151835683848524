import { Routes, Route } from "react-router-dom";

import Welcome from "./pages/Welcome";
import { AdminDashboard } from "./pages/AdminDashboard";
import { UserProfile } from "./pages/UserProfile";

import { AuthProvider } from "./features/Authentication/context/authContext";
import { GlobalProvider } from "./context/GlobalContext";
import { Feed } from "./pages/Feed";
import { NotFound } from "./components/NotFound";
import { ProtectedRoutes } from "./features/Authentication/components/ProtectedRoutes";
import { Conjouons } from "./pages/Conjouons";
import { ConjouonsProvider } from "./features/Conjouons/context/ConjouonsContext";
import { Conjugation } from "./pages/Conjugation";
import { ConjugationProvider } from "./features/Conjugation/context/ConjugationContext";
import { Abc } from "./pages/Abc";

import { Phrases } from "./pages/Phrases";
import { PhrasesProvider } from "./features/Phrases/context/PhrasesContext";
import { Stats } from "./pages/Stats";
import { Plus } from "./pages/Plus";
import { UserProvider } from "./context/UserContext";


function App() {

  return (
    <GlobalProvider>
      <AuthProvider>
        <UserProvider>

          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="*" element={<NotFound />} />

            <Route element={<ProtectedRoutes />}>
              <Route path="/feed" element={<Feed />} />
              <Route path="/stats" element={<Stats />} />
              <Route path="/plus" element={<Plus />} />
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/profile" element={<UserProfile />} />

              <Route path="/conjugation" element={
                <ConjugationProvider>
                  <Conjugation />
                </ConjugationProvider>
              } />

              <Route path="/phrases" element={
                <PhrasesProvider>
                  <Phrases />
                </PhrasesProvider>
              } />
            </Route>
          </Routes>

        </UserProvider>
      </AuthProvider>
    </GlobalProvider>
  );
}

export default App;

