import styled from 'styled-components';
import { useContext, useState, useRef } from 'react';
import { ConjugationContext } from '../context/ConjugationContext';
import { GlobalContext } from '../../../context/GlobalContext';
import { BsCheck } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { TbZoomQuestion } from 'react-icons/tb';

export const Tense = ({ children, title, done, pholder }) => {

    const {
        selectedLang
    } = useContext(GlobalContext);

    const {
        verb,
        indexList,
        setIndexList,
        searchRandomTense,
        TensesList,
        tenses
    } = useContext(ConjugationContext);

    const [correct, setCorrect] = useState(true);
    const [active, setActive] = useState(false);

    const refInput = useRef();
    const refCheck = useRef();

    const editHandler = () => {
        console.log(refInput.current.style.display);
        refInput.current.style.display = 'flex';
        refInput.current.focus();
    };

    const checkHandler = (e) => {
        setActive(true);
        if (e.key === "Enter") {
            checker();
        }
    };

    function focusHandler() {
        setActive(true);
    }

    function checker() {
        let lowerRandomConj = refInput.current.value.toLowerCase();
        let lowerConj = verb.conjugation[tenses[title]][selectedLang][0][0].toLowerCase();

        if (lowerRandomConj == lowerConj) {
            refInput.current.style.display = 'none';
            setCorrect(true);            
            setIndexList(current => [...current, title]);
            searchRandomTense();
        } else {
            console.log('try again');
            setCorrect(false);
        }
        refCheck.current.style.display = 'flex';
        setActive(false);
        // console.log('title :' + title);
        // console.log(indexList)
    }

    return (
        <StyledTense >

            {active && <span className='target' onClick={checker}>🎯</span>}

            <div className="tenseContainer" name={tenses[title]}>

                <input type="text"
                    className="box tense"
                    name={tenses[title]}
                    id={tenses[title]}
                    ref={refInput}
                    placeholder={pholder}
                    onKeyUp={checkHandler}
                    onClick={focusHandler}
                />

                <div className="children" >
                    <div className="rightText">
                        <p onClick={editHandler} >{verb.conjugation[tenses[title]][selectedLang][0][0]}</p>
                    </div>
                    {children}
                </div>
            </div>

            <div className="checkMark" ref={refCheck} >
                {correct ? <i className='correct'><BsCheck /></i> :
                    <i className='error'><IoClose /></i>
                }
            </div>

        </StyledTense>
    );
};

const StyledTense = styled.div` 

-webkit-border-radius: .5rem;
 -khtml-border-radius: .5rem;
   -moz-border-radius: .5rem;       

position: relative;

.target{
        z-index: 5;
        display: flex;
        position: absolute;
        top: -.95rem;
        right: -.9rem;
        font-size: 1.3rem;
        :hover{
            cursor: pointer;
        }
    }

.tenseContainer{
    position: relative;
    display: flex;
    flex-direction: column;

    height: 2rem;
    max-width: 7.5rem;
    overflow: hidden;

    cursor: pointer;            
    margin-bottom: .3rem;
    /* background: hsla(0, 0%, 100%, 0.1); */

    outline: 1px solid var(--fontColor);
    border-radius: .5rem; 
}

.box{
    display: flex;
    /* background: hsla(0, 0%, 100%, 0.1); */
    outline: none
    border-radius: .5rem;
    /* font-weight: bold;    */

    ::placeholder{
        color: var(--disabled);
    }
}
    

button {
    -webkit-border-radius: 1rem;
     -khtml-border-radius: 1rem;
       -moz-border-radius: 1rem;
            border-radius: 1rem;
    background: transparent;
    
    opacity: ${props => (props.see ? 1 : .5)}; 
    cursor: pointer;

    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
    
}

.children{
        width: 100%;
        display: flex; 
        flex-direction: column;    
        padding: .5rem;
        justify-content: center;
        p{
            width: 100%;
            /* background: green; */
            white-space: nowrap;
        }
    }


input{
    /* max-width: 6.7rem; */
    width: 100%;
    display: flex;

    background: transparent;
    color: var(--fontColor);         
    /* font-weight: bold; */
    font-size: 1rem;
    padding: .5rem;

    -webkit-border-radius: .5rem;
     -khtml-border-radius: .5rem;
       -moz-border-radius: .5rem;
            border-radius: .5rem;

    outline: none;   

    ::placeholder{
        font-weight: bold; 
        font-size: .9rem;
    }
      
    } 

.rightText{
    display: flex;
}

.checkMark{
    
    display: none;
    position: absolute;
    top: -.4rem;
    right: -.4rem;
    /* top: -.6rem;
    right: -.8rem; */
    font-size: 1.2rem; 
    
    i{
        width: 1rem;
    height: 1rem;
    background: var(--bgColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    outline: 2px solid var(--disabled);
    }

    
}

.correct{    
    color: var(--colorCheck);
}

.error{    
    color: crimson;
}

@media all and (min-width: 600px)  {
    max-width: 20rem;
    /* background: green; */
}
             
   
`;