import { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { BiTag } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import { FiSearch, FiDelete } from 'react-icons/fi';
import { PhrasesContext } from '../context/PhrasesContextOld';

export const SearchBar = () => {

  const {
    word,
    setWord,
    tagList,
    tag,
    setTag,
    wordFilter,
  } = useContext(PhrasesContext);


  const [selectag, setSelectag] = useState(false);
  const inputRef = useRef();
  const searchTagRef = useRef();

  const searchHandler = () => {
    let text = inputRef.current.value.toLowerCase();
    setWord(text);
    inputRef.current.value = '';
  };

  const selecTagHandler = () => {
    setSelectag(!selectag);
  };

  const tagHandler = () => {
    // setTag('');
    setTag(null);
    wordFilter(word);
    setSelectag(!selectag);
    // console.log('current Word: ', word);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchHandler();
    }
  };

  const setTagHandler = (e) => {
    setTag(e.target.innerHTML);

  };


  useEffect(() => {

    let tagElements = document.querySelectorAll('.tagItem');

    tagElements.forEach(tag => tag.addEventListener("click", e => {
      setTagHandler(e);
    }));

  }, []);


  return (
    <StyledSearchBar phSelect={selectag}>
      <div className="headers">

        <div className="input_container">
          <input
            type="text"
            placeholder='Buscar'
            className='searchInput'
            ref={inputRef}
            onKeyDown={handleKeyDown}
          />
          <div className="searchButton">
            <button onClick={searchHandler}><FiSearch /></button>
          </div>
        </div>

        <div className="button_container">
          <button className='filterTag' onClick={selecTagHandler}>
            <p>{tag}</p>
            <i><BiTag /></i>
          </button>
        </div>
      </div>

      <div className="tagsMenuMobile">
        <div className="tags" >
          <p className='optionTag' onClick={tagHandler}><FiDelete className='closeTagIcon' onClick={tagHandler} /></p>
          <div className="optionsTag noSelect noScrollBar" >
            {tagList.map(tag => {
              return <p key={tag} className='tagItem' >{tag}</p>;
            })}
          </div>
          <p className='closeTags' onClick={selecTagHandler}><IoClose /></p>
        </div>
      </div>

    </StyledSearchBar>
  );
};

const StyledSearchBar = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 100%;
      height: 100%;
      padding: .5rem;
      align-items   : center;
      gap: 1rem;
      

      .headers{
        display: flex;
      }

      .tagsMenuMobile{
      width: 100%;   
      height: ${props => (props.phSelect ? "12rem" : '0rem')}; 
      
      transition: all .4s ease-in;
      overflow: hidden;
      
    }

      .input_container{        
        
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--disabled);
        border-radius: 5px;     

        input{
            display: flex;
        }
      }

      .searchButton{
        display: flex;
        padding: 0.2rem;  
        
        button{
          color: var(--bgColor);
          display: flex;
            border: none;
            /* background: hsla(0, 0%, 100%, .2); */
            background: var(--disabled);
            border-radius: 3px;
            padding: .2rem;
            font-size: 1.5rem;

            :hover{
              cursor: pointer;
              color: var(--color5)
            }
        }
      }

    .searchInput{

         background: transparent;
         /* background: green; */
         width: 100%;
         padding: 0rem 1rem;
         font-size: 1.5rem;
         color: var(--color5);

         :focus, :active{
            outline: none;
         }
         ::placeholder{
            color: var(--disabled);
         }
    }

    .button_container{
      /* background: green; */
      width: 100%;
      min-width: 7rem;
      display: flex;
      flex-direction: column;
      justify-content: center;   
      

    }

    .filterTag{
      display: flex;
      justify-content: end;
      gap: 1rem;
      color: var(--color5);

      background: transparent;
      font-size: 1.1rem;
      overflow: hidden;
      
      i{
        color: var(--disabled);
        font-size: 1.5rem;
      }
    }

    .tags{
      display: block;    
      
      background: var(--bgColor);
      padding: 1rem;

      p{
        font-size: 1.2rem;
         :hover{
            color: var(--color5);
         }
      }
    }

    .optionTag{
      display: flex;
      justify-content: end;
      color: var(--disabled);
      
    }
    
    .closeTagIcon{
      font-size: 1.5rem;

    }

    .optionsTag{
      
      height: 8rem;
      overflow: scroll;
      
      p{
        color: var(--disabled);
      }
      :hover{
        cursor: pointer;
      }
    }

    .addTag{
      display: flex;
      justify-content: center;
    }

    .closeTags{
      display: flex;
      /* background: red; */
      justify-content: center;
      padding-top: .2rem;
      font-size: 1.5em;
      color: var(--disabled);
    }
    

`;