
import styled from 'styled-components';

import { useContext } from 'react';
import { ConjouonsContext } from '../context/ConjouonsContext';
import { FullTense } from './FullTense';
import { ModeInfo } from './ModeInfo';

export const Info = () => {

    const { info } = useContext(ConjouonsContext);


    return (
        <StyledInfo >
            {info.about === 'mode' && <ModeInfo mode={info.title} />}
            {info.about === 'tense' && <FullTense tense={info.title} />}
        </StyledInfo>
    );
};


const StyledInfo = styled.div`
  
 background: var(--bgColor);


 border-radius: 1rem;
 padding: 1rem;

 display: flex;
 flex-direction: column;
 gap: 1rem;

`;