import styled from 'styled-components';

import { LoginForm } from "../features/Authentication/components/LoginForm";
import { AbstractLogin } from "../features/Authentication/components/AbstractLogin";
import { Modal } from "../components/Modal";


function Welcome() {

  return (
    <LayoutWelcome>

      <h1>q ... b</h1>
      <AbstractLogin />

      <Modal>
        <LoginForm />
      </Modal>
    </LayoutWelcome>
  );
}

export default Welcome;

const LayoutWelcome = styled.div`

width: 100%;
height: 100vh;

background-color: var(--color6);
color: white;

display: flex;
flex-direction: column;
align-items: center;


h1{
  margin-top: 1rem;
  padding-bottom: 1rem;
}
    

  
`;

