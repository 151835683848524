import styled from 'styled-components';
import { GlobalContext } from '../context/GlobalContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
    
export const SelectedLang = () => {

    const { selectedLang } = useContext(GlobalContext);

    const refLang = useRef()

    useEffect(() => {
        refLang.current.animate([{ opacity: '1', offset: 0.3 }], {duration: 3000});
      
    }, [selectedLang])    

   return (
      <StyledSelectedLang>
           <p className='selectLang' ref={refLang} >{selectedLang}</p>
      </StyledSelectedLang>
   )
}
    
const StyledSelectedLang = styled.div`
    
.selectLang{
       position: absolute;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%);

        font-size: 1.3rem;  
        opacity: 0;
  }

`;