import styled from 'styled-components';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { GlobalContext } from '../../../context/GlobalContext';
import { PhrasesContext } from '../context/PhrasesContext';

export const Folder = ({ tag, filter }) => {

    const {
        refTags
    } = useContext(GlobalContext);

    const {
        selectedTag,
        setSelectedTag
    } = useContext(UserContext);

    const {
        refModifTags
    } = useContext(PhrasesContext);

    const tagHandler = () => {
        setSelectedTag(tag);
        filter();
        refTags.current.value = tag;
        if (refModifTags.current) {
            refModifTags.current.value = tag;
        }
    };

    return (
        <StyledFolder onClick={tagHandler}>
            <span>📁</span>
            <p>{tag}</p>
        </StyledFolder>
    );
};

const StyledFolder = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    
    span{
        font-size: 2rem;
    }

    :hover{
        color: var(--color5);
        cursor: pointer;
    }

`;