import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ConjouonsContext } from '../context/ConjouonsContext';

export const Verb = () => {

    const {
        setIsVerbMoving,
        score,
        currentTry,
        setScore,
        triedIndex,
        contactPoint,
        randomVerb,
        setRandomVerb,
        searchRandomTense,
        gameStatus,
        setGameStatus,
        randomVerbRef
    } = useContext(ConjouonsContext);

    useEffect(() => {
        setRandomVerb(randomVerbRef.current);
    }, []);

    const pointerDownHandler = (e) => {

        setIsVerbMoving(true);
        setScore({ ...score, playing: true, done: triedIndex });

        if (!gameStatus) {
            searchRandomTense();
            setGameStatus(true);
        }
    };

    return (
        <StyledVerb id='verbContainer' show={score.playing}>

            <p onPointerDown={pointerDownHandler} >
                {currentTry ? currentTry[0] : 'Start'}
            </p>

            <div id="randomVerb" ref={randomVerbRef} data-tense=''>
                <div ref={contactPoint} id="contactPoint"></div>
                <p>{currentTry ? currentTry[0] : 'Start'}</p>
            </div>
        </StyledVerb>
    );
};

const StyledVerb = styled.div`

    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 

    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

        :hover{
           cursor: pointer;        
        }        

    p{
        background: hsla(0, 0%, 100%, .06);

        border-radius: .5rem;
        padding: .3rem .5rem;
        color: var(--color5);
        font-weight: bold;
    }

    #randomVerb{
        display: ${props => (props.show ? 'flex' : 'none')};

        position: absolute;  
        justify-content: start;
        align-items: center;
        
        top: 0;
        left: 0;    
        
        p{  
            position: relative;
        }
    }

    #contactPoint{
        position: absolute;
        width: 100%;
        display: flex;        
        width: 2rem;
        height: 2rem;
    }    
`;