import styled from 'styled-components';

export const ModeInfo = ({ mode }) => {



    return (
        <StyledModeInfo>
            <div className="description">
                <h2>{mode}</h2>
                <h4>Ce mode comprend les temps:</h4>
                <p><strong>Présent:</strong> </p>

                <div className="emojis">
                    <span>🧛</span>
                    <span>🦉</span>
                    <span>🔮</span>
                    <span>🎃</span>
                    <span>🍭</span>
                </div>
            </div>
        </StyledModeInfo>
    );
};


const StyledModeInfo = styled.div`

    background: var(--bgColor);

    display: flex;
    flex-direction: column;

    max-width: 50rem;
    height: 30rem;

    padding: 1rem;

    overflow: scroll;  


    .description{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 1rem;

        h1{
            padding-bottom: 1rem;
        }

        p{
            display: grid;
            grid-template-columns: 150px 1fr;
            justify-items: start;
            align-items: flex-start;
        }
    }

    .emojis{
        width: 100%;
        display: flex;
        font-size: 2rem;
        align-items: center;
        justify-content: space-evenly;
    }

    ::-webkit-scrollbar {
    width: 1rem;
    height: .5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px transparent; 
    border-radius: .5rem;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: hsla(0,0%,0%,.1);
    width: 10px;
    height: 10px;
    border-radius: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: hsla(0,0%,0%,.1); 
    
    }    

`;