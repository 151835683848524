import styled from 'styled-components';
import { useContext } from 'react';
import { ConjugationContext } from '../context/ConjugationContext';
import { useRef } from 'react';
import { useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';

export const Verb = () => {

    const {
        language,
        setLanguage,
        langToggle,
        setLangToggle,
        foreignLang,
        selectedLang,
        setSelectedLang
    } = useContext(GlobalContext);

    const {
        verb,
        vref,
        verbRef,
        tenses,
        isVerbMoving,
        refContactPoint,
        setIsVerbMoving,
        getRandomNumber,
        randomTense,
        setRandomTense,
    } = useContext(ConjugationContext);


    const PointerDownHandler = (e) => {
        setIsVerbMoving(true);
        console.log('pointerDown verb')
    };

    return (
        <StyledVerb see={isVerbMoving} >
            <div className='movingVerb' ref={verbRef}>
                <div ref={refContactPoint} id="contactPoint"></div>
                <p>{randomTense ? verb.conjugation[randomTense][selectedLang][0][0] : 'start'}</p>
            </div>

            <div onPointerDown={PointerDownHandler}>
                <p className="verb" data-name={randomTense} ref={vref} data-tense={randomTense ? verb.conjugation[randomTense][selectedLang][0][0] : 'start'} >{randomTense ? verb.conjugation[randomTense][selectedLang][0][0] : 'start'}</p>

            </div>
        </StyledVerb>
    );
};

const StyledVerb = styled.div`   
    
    z-index: 20;
    height: 1.5rem;
    display: flex;
    margin-bottom: .1rem;
    align-items: center;        
        
    .verb{
        
    display: flex;
    opacity: ${props => (props.see ? .1 : 1)};

    align-self: center;
    outline: 1px solid var(--fontColor);
    font-weight: bold;
    padding: .2rem .4rem;
    cursor: pointer;
    margin-bottom: 1rem;
        
    -webkit-border-radius: .5rem;
     -khtml-border-radius: .5rem;
       -moz-border-radius: .5rem;
            border-radius: .5rem;
      }
      
      .movingVerb{
        position: absolute;
        display: ${props => (props.see ? 'flex' : 'none')};
        /* display: flex; */
        font-weight: bold;
      }

      #contactPoint{
          position: absolute;
          /* background: green; */
        width: 1rem;
        height: 1rem;
    }    

`;