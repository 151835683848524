import styled from 'styled-components';
import { TopBar } from '../components/Nav/TopBar';
import { AppBar } from '../components/Nav/AppBar';
import { GlobalContext } from '../context/GlobalContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { authContext } from '../features/Authentication/context/authContext';
import brain from '../assets/brainStorm.png';
import { SideBar } from '../components/Nav/SideBar';
import { AddButton } from '../components/Nav/AddButton';


export const Stats = () => {

    const {
        foreignLang,
        setActiveApp
    } = useContext(GlobalContext);

    const { user } = useContext(authContext);

    const { userPhrases, getUserPhrases } = useContext(UserContext);


    useEffect(() => {
        setActiveApp('stats');
        // getUserPhrases(user, foreignLang)

    }, []);

    return (
        <StyledStats>
            <TopBar />
            <AppBar />
            <SideBar />
            <AddButton />

            {/* <div className="statsContainer">
                {userPhrases ?
                    <p>Has agregado {userPhrases.length} frases</p>
                    :
                    <p>Loading data...</p>
                }
            </div> */}

            <div className="brainContainer">
                <img src={brain} alt="" />
            </div>

        </StyledStats>
    );
};

const StyledStats = styled.div`

width: 100%;
height: 100%;

display: flex;
flex-direction: column;
justify-content: start;
align-items: center;

.statsContainer{
    display: flex;
    padding: 1rem;

    /* color: var(--fontColor); */
}

.brainContainer{
    max-width: 15rem;
    padding: 1rem;

    img{
        width: 100%;
        border-radius: 5rem;
    }
}


`;
