import { createGlobalStyle } from 'styled-components';


const lightTheme = {
  body: 'hsl(213, 100%, 96%)',
  font: 'hsl(228, 2%, 21%)',

  toggleBorder: 'hsl(0, 0%, 100%)',
  background: 'hsla(0, 0%, 100%, 1)',
  clearBG: 'hsl(212, 100%, 98%)',
  // alphaBg: 'hsla(0, 0%, 0%, .06)',
  alphaBg: 'hsla(0, 0%, 100%, .5)',

  disabled: 'hsla(0, 0%, 0%, .2)',
};

const darkTheme = {
  body: 'hsl(225, 14%, 11%)',
  font: '#FAFAFA',
  toggleBorder: 'hsl(211, 17%, 50%)',
  background: 'hsla(0, 90%, 4%, .1)',
  clearBG: 'hsl(228, 14%, 14%)',
  alphaBg: 'hsla(0, 0%, 100%, .06);',
  disabled: 'hsla(0, 0%, 100%, .2)',
};


export const GlobalStyles = createGlobalStyle`

    *, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  border: none;  

}

:root{
  --bgColor : ${props => (props.dark ? darkTheme.body : lightTheme.body)};
  --fontColor : ${props => (props.dark ? darkTheme.font : lightTheme.font)};
  --clearBG : ${props => (props.dark ? darkTheme.clearBG : lightTheme.clearBG)};

  --dark1: ${props => (props.dark ? darkTheme.background : lightTheme.background)};
  --alphaBg: ${props => (props.dark ? darkTheme.alphaBg : lightTheme.alphaBg)};
  --disabled: ${props => (props.dark ? darkTheme.disabled : lightTheme.disabled)};
  --colorCheck: ${props => (props.dark ? 'yellowgreen' : 'green')};

  --bgColorLight : hsl(220, 13%, 20%);

  /* --color1: hsl(178, 77%, 57%); */
  --color1: hsl(43, 78%, 47%);
  --color2: hsl(0, 0%, 50%);
  --color3: hsl(224, 14%, 21%);
  --color4: hsl(204, 24%, 28%);
  /* --color5: hsl(177, 60%, 59%); */
  --color5: #1a9bd7;
  --color6: hsl(224, 14%, 11%);
  
  --color3: #2e323d;

  --primary: hsla(0, 0%, 70%, 1);
  --secondary: hsl(188, 94%, 70%);
  --tertiary: hsl(273, 96%, 72%);

  --palettePrimary: hsl(303, 96%, 78%);
  --paletteSecondary: hsl(188, 100%, 89%);
  --paletteTertiary: hsl(273, 100%, 89%);

}


body {
  background-color: var(--bgColor);
  color: var(--fontColor);
  font-family: 'Quicksand', sans-serif;

  /* font-family: 'Comfortaa', cursive; */
  /* font-family: 'Nunito', sans-serif; */
  /* font-family: 'Fredoka', sans-serif; */
  /* font-family: 'Poiret One', cursive; */
}

input{
  font-family: 'Quicksand', sans-serif;
}

a{
  text-decoration: none;
  color: var(--fontColor);
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.noSelect:focus {
    outline: none !important;
}


.noScrollBar{  

  scrollbar-width: none;

  /* scrollbar-color: transparent transparent; */
  
  /* ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar-track {
    background: transparent; 
    height: .1rem;
    width: 5rem;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent; 
    width: 5rem;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: transparent; 
  }
  ::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
  }
  ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  }  */

}

`;

