import styled from 'styled-components';
import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { HiMenu } from 'react-icons/hi';
import { Notification } from '../Notification';
import { Link } from 'react-router-dom';
import { ThemeToggle } from '../ThemeToggle';
import { VscSettings } from 'react-icons/vsc';
import { TbDotsVertical } from 'react-icons/tb';
import { Logout } from '../../features/Authentication/components/Logout';
import { CgMenuGridO } from 'react-icons/cg';
import { BiUser } from 'react-icons/bi';
import { useState } from 'react';
import { LangToggle } from '../LangToggle';
import { SelectedLang } from '../SelectedLang';
import { AppBar } from './AppBar';
import { authContext } from '../../features/Authentication/context/authContext';
import { UserContext } from '../../context/UserContext';
import { useEffect } from 'react';


export const TopBar = () => {

    const {
        isSideBarVisible,
        setIsSideBarVisible,
        selectedLang
    } = useContext(GlobalContext);

    const { user, userRole } = useContext(authContext);

    const {
        getPgUserInfo,
        pguserInfo,
    } = useContext(UserContext);

    const showSideBar = () => {
        setIsSideBarVisible(!isSideBarVisible);
    };

    const userinfoHandler = () => {
        getPgUserInfo(user);
    };    

    return (
        <StyledTopBar className='noSelect' >

            <div className="topBarMain">
                <div className="itemContainer">
                    <Link to={'/feed'}><div className="siteTitle">
                        {/* <p>Qbelex</p> */}
                        <p>Qb</p>
                    </div>   </Link>
                </div>

                <div className="left">
                    <div className="langTog">
                        <LangToggle />
                        <div className="selLang">
                            <SelectedLang />
                        </div>
                    </div>

                    <div className="pic">
                        <TbDotsVertical
                            
                            className='dots' />

                        <div className="profileMenu">
                            <div className="groupe">
                                <i
                                    onClick={userinfoHandler}><Link to={'/profile'}><BiUser /></Link></i>
                                <ThemeToggle />
                                {userRole.admin &&
                                    <i><Link to={'/dashboard'}><VscSettings /></Link></i>
                                }
                                {/* <span onClick={() => { document.location.reload(); }}>♻️</span> */}

                                <Logout />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledTopBar>
    );
};

const StyledTopBar = styled.div`

position: relative;
z-index: 1000;
width: 100%;
/* height: 4rem; */
display: flex;
background: green;

.selLang{
  position: absolute;
  top: 1rem;
  left: -1rem;       
}

.topBarMain{
    width: 100%;
    background: var(--color3); 
    padding: .2rem ;

    /* position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 3rem; */

    display: flex;
    justify-content: space-between;
    align-items: end;
    
    font-size: 1.7rem; 
    
    color: hsla(0,0%,100%,.8);
}    

.left{
    display: flex;
    align-items: center;
    gap: 1rem;
}
    
.siteTitle{
    color: hsla(0,0%,100%,.8);
    display: flex;
    align-items: center;
    padding-left: .5rem;
    gap: 3rem;
    
    p{
        font-size: 1.4rem;
        font-family: 'Comfortaa', cursive; 
        span{
            color: hsla(0,0%,100%,.8);
        }           
    }        
}

i{
    display: flex; 
    justify-content: center;
    align-items: center;
    
    height: 100%;
    align-items: center;
    
    &:hover{
        cursor: pointer;
    }               
}    

.itemContainer{
    display: flex;        
}  

.menuIcon{
    color: whitesmoke;
    margin-left: .5rem;
    display: flex; 
    justify-content: center;
    align-items: center;
    
    &:hover{
        cursor: pointer;
    }       
}

.pic{
    /* background: red; */
    padding: .2rem;
    position: relative;
    display: flex;
    gap: 2rem;

    :hover .profileMenu{
        display: flex;
    }            
}

.dots{
    cursor: pointer;
    margin-right: .5rem;
    font-size: 1.3rem;
}

.profileMenu{
    position: absolute;
    z-index: 1000;
    display: none;
    top: 1.4rem;
    right: .1rem;
    border-radius: 1rem;

    background: var(--bgColor);
    box-shadow: .1rem .1rem 1rem .1rem hsla(0,0%,0%,.1);
}

.groupe{
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 1rem;
    justify-content: center;
    padding: .5rem;
    font-size: 1.3rem;

    &::after{
            position: absolute;
            content: '';
            top: .7rem;
            right: .4rem;

            width: .5rem;
            height: .5rem;
            background: var(--color5);
            border-radius: 50%;
        }
}

.langTog{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    p{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
    }
}  


`;