import styled from 'styled-components';
import { IoIosCloud, IoIosCloudDone } from "react-icons/io";
import { useState, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

export const UserSync = ({ pgUser, user }) => {

    const { addPgUser } = useContext(UserContext);

    const [synched, setSynched] = useState(false);

    const syncUser = () => {
        // setSynched(true);
        console.log(user);
        addPgUser(user);
    };

    return (
        <StyledUserSync>
            {pgUser ?
                <i className='synched'><IoIosCloudDone /></i>
                :
                <i onClick={syncUser}><IoIosCloud /></i>
            }
        </StyledUserSync>
    );
};

const StyledUserSync = styled.div`
    
    i{
        color: var(--disabled);
        font-size: 1.5rem;
        
        :hover{
            cursor: pointer;
        }
    }
    
    .synched{
        color: hsla(204, 94%, 75%, 0.771);

    }

    

`;