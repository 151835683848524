import styled from 'styled-components';
import { GlobalContext } from '../../../context/GlobalContext';
import { ConjugationContext } from '../context/ConjugationContext';
import { useContext } from 'react';
import { useState } from 'react';
import { Verb } from './Verb';
import { BoardDnd } from './BoardDnd';

export const DragnDrop = () => {

    const {
        language,
        setLanguage,
        langToggle,
        setLangToggle,
        setActiveApp
    } = useContext(GlobalContext);

    const {
        verb,
        vref,
        tenses,
        refContactPoint,
        isVerbMoving,
        setIsVerbMoving,
        RandomTense,
        setRandomTense,
        getRandomNumber,
        verbRef,
        searchRandomTense,
        indexList,
        randomIndex,
        setIndexList,
        cheat,
        setCheat
    } = useContext(ConjugationContext);

    const [randomVerb, setRandomVerb] = useState(null);
    const [selectedTense, setSelectedTense] = useState(null);
    const [lastSelectedID, setLastSelectedID] = useState(null);
    const [selectedTemp, setSelectedTemp] = useState('');

    let tensesList = Array.from(document.querySelectorAll('.tense'));

    const resetVerbPosition = (e) => {
        const FINGER_SPACE_X = 80;
        const FINGER_SPACE_Y = 45;
        let posX = e.clientX - FINGER_SPACE_X;
        let posY = e.clientY - FINGER_SPACE_Y;
        verbRef.current.style.top = `${posY}px`;
        verbRef.current.style.left = `${posX}px`;
    };

    const arrayOverlaps = (element, list) => {
        const rect1 = element.getBoundingClientRect();

        let collisionList = list.map((temp) => {
            const rect2 = temp.getBoundingClientRect();
            const isInHoriztonalBounds = rect1.x < rect2.x + rect2.width && rect1.x + rect1.width > rect2.x;
            const isInVerticalBounds = rect1.y < rect2.y + rect2.height && rect1.y + rect1.height > rect2.y;
            const isOverlapping = isInHoriztonalBounds && isInVerticalBounds;

            let id = temp.getAttribute('id');
            return {
                id: id,
                isOverlapping: isOverlapping
            };

        });

        return collisionList;
    };

    const getSelectedTemp = (tempList) => {

        tempList.map((temp) => {
            let element = document.getElementById(temp.id);

            if (temp.id === lastSelectedID) {
                element.style.outline = '2px dashed var(--fontColor)';
            } else {
                element.style.outline = 'none';
            }

            if (temp.isOverlapping) {
                setLastSelectedID(temp.id);
                setSelectedTemp(temp.id);                
            }
            return element;
        });
    };

    const checkTemp = () => {
        let lastSelected = document.getElementById(lastSelectedID); 

        if (lastSelectedID !== null) {
            if (selectedTemp === vref.current.dataset.name) {

                // lastSelected.style.background = 'hsla(0, 0%, 100%, 0.1)';
                
                searchRandomTense();
                lastSelected.value = vref.current.dataset.tense;
                lastSelected.style.display = 'none'; 

                let parentNode = lastSelected.parentNode.parentNode;
                let child = parentNode.lastChild
                child.style.display = 'flex';                 

            } else {
                // lastSelected.style.background = 'crimson';
            }
        }
    };

    const cleanSelection = () => {
        if (lastSelectedID !== null) {
            let lastSelected = document.getElementById(lastSelectedID);
            lastSelected.style.outline = 'none';
            setLastSelectedID(null);
        }
    };

   
    const pointerDownHandler = (e) => {
        resetVerbPosition(e);
    };

    const pointerMoveHandler = (e) => {
        setSelectedTense(e.target.name);
        console.log(e.target.name);

        if (isVerbMoving) {
            resetVerbPosition(e);

            const tempList = arrayOverlaps(refContactPoint.current, tensesList);
            getSelectedTemp(tempList);
        }
    };

    const pointerUpHandler = () => {
        checkTemp();
        cleanSelection();
        setIsVerbMoving(false);
    };

    return (
        <StyledDragnDrop
            onPointerMove={pointerMoveHandler}
            onPointerDown={pointerDownHandler}
            onPointerUp={pointerUpHandler}

            touch={isVerbMoving}
            className='noSelect'
        >
            <div className="board">
                
                <Verb />

                <div className="box">                    
                    <BoardDnd />
                </div>

            </div>               
        </StyledDragnDrop>
    );
};

const StyledDragnDrop = styled.div`
    /* background: hsla(129, 61%, 39%, 0.2); */
    /* background: yellowgreen; */
    width: 100%;
    
    display: flex;
    touch-action: none;
    
    .board{
        /* background: hsla(129, 61%, 39%, 0.2); */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1.5rem ;
        padding-bottom: 5rem;
      } 

      

`;