import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { authContext } from '../context/authContext';

export const ProtectedRoutes = () => {

    const { user, loading } = useContext(authContext);

    if (loading) return <h1> Loading </h1>;
    if (!user) return <Navigate to='/' />;

    return <Outlet />;
};
