import styled from 'styled-components';
import { useState } from 'react';


export const Pronoun = ({ placeh}) => {

    const [see, setSee] = useState(false);
    

    const checkHandler = () => {
        setSee(!see);
    };

    return (
        <StyledPronoun >
            <input type="text" placeholder={see ? placeh : ' '} />
            <p onClick={checkHandler} >{see ? '😜' : '🙄'}</p>

        </StyledPronoun>
    );
};

const StyledPronoun = styled.div`
    display: flex;
    gap: 1rem;

    padding: 0rem .5rem;

    p{
        cursor: pointer;
        font-size: 1.5rem;
    }

    
    input{
        
        border : none;
        padding: .1rem 0.5rem;

        background: transparent;
        color: var(--color1);
        font-size: 1rem;

        border-bottom: 1px solid var(--fontColor);
        display: flex;
        align-items: end;

         :focus, :active{                
                outline: none;
            }

        ::placeholder{
            color: var(--color1);
            
        }
    }

input[type="checkbox"] {
     appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  /* ...existing styles */
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid var(--color5);
  outline-offset: max(2px, 0.15em);
}
    
   
  
`;