import styled from 'styled-components';
import { useContext, useState } from 'react';

import { TopBar } from '../components/Nav/TopBar';
import { GlobalContext } from '../context/GlobalContext';
import { authContext } from '../features/Authentication/context/authContext';
import { UserContext } from '../context/UserContext';

import { IoEarth } from 'react-icons/io5';
import { FaUserAstronaut } from 'react-icons/fa';
import { MdEditNote } from "react-icons/md";

import { useLocalStorage } from '../hooks/useLocalStorage';
import { useEffect } from 'react';


export const UserProfile = () => {

  const { language, foreignLang } = useContext(GlobalContext);
  const { user, userRole } = useContext(authContext);

  const {
    pgusers,
    updatePgUser,
    getPgUserInfo,
    pguserInfo,
    setPguserInfo,
  } = useContext(UserContext);

  const [subscription, setSubscription] = useLocalStorage('subscription', '');
  const [edition, setEdition] = useState(false);
  const [regUser, setRegUser] = useState(
    {
      'username': '',
      'email': '',
    }
  );

  const userInfoHandler = () => {
    setEdition(!edition);
    getPgUserInfo(user);
  };

  const changeHandler = ({ target: { name, value } }) => {
    setRegUser({ ...regUser, [name]: value });
  };

  const subscribeHandler = () => {
    setSubscription(!subscription);
  };

  const saveHandler = () => {
    let { uid } = user;
    let { username } = regUser;
    let newUser = { username, uid };

    updatePgUser(newUser);
    getPgUserInfo(user);

  };

  // useEffect(() => {
  //   console.log(pguserInfo);
  // }, []);


  return (
    <StyledUserProfile subs={subscription}>
      <TopBar />

      <div className="contentSize noSelect">
        <div className="card">
          <div className="userImage">
            <FaUserAstronaut />
            <p>cambiar</p>
          </div>

          <div className="group">
            <div className="cardContainer">

              <div className="userInfo">
                <div className="name">
                  <label htmlFor="">Name:</label>
                  {edition ?
                    <input
                      type="text"
                      name='username'
                      onChange={changeHandler}
                      placeholder={
                        pguserInfo.username ? pguserInfo.username
                          :
                          String(user.email).replace(/@.*/, '')
                      } />
                    :
                    <span> {pguserInfo.username}</span>
                  }
                </div>

                <div className="name">
                  <label htmlFor="">Email :</label>
                  {/* {edition ?
                    <input type="email" placeholder={user.email} />
                    :
                    <span>{user.email}</span>
                  } */}
                  <span>{user.email}</span>
                </div>

                <br />

                <div className="plan">
                  <p className='subs'>{subscription ?
                    'Premium 💎'
                    :
                    'Free Plan'
                  }</p>
                  <div className="subscribe">
                    <button onClick={subscribeHandler}>{subscription ? 'Unsubscribe' : 'Subscribe'}</button>
                  </div>
                </div>

              </div>

              <div className="language">
                <div className="foreign">
                  <span><IoEarth /></span>
                  {language === 'es' && <p className='lang motherLang'>Español</p>}
                  {foreignLang === 'en' && <p className='lang'>English</p>}
                  <p className='lang'>Français</p>
                </div>
              </div>

              <div
                className="edit"
                onClick={userInfoHandler}
              >
                {edition ?
                  <button
                    className="savebtn"
                    onClick={saveHandler}
                  >Save</button>
                  :
                  <i><MdEditNote /></i>
                }
              </div>
            </div>

            <div className="cardContainer topics">
              <h4>Temas de interés</h4>
              <p>📈 negocios</p>
              <p>♻️ ecología</p>
              <p>🧑‍🎨 arte</p>
              <p>🧮 matemáticas</p>
            </div>

            {/* <div className="cardContainer groups">
              <h4>Groups</h4>
              <p>👪 familia </p>
              <p>💈amigos </p>
              <p>🧑‍🎓 escuela </p>
            </div> */}

          </div>
        </div>

      </div>

    </StyledUserProfile>
  );
};

const StyledUserProfile = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1rem;

    font-size: 1.2rem;

  .contentSize{
    display: flex;
    justify-content: center;    
  }

  .card{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center ;
    gap: 1rem;   
  }

  .userImage{
    position: relative;
    background: var(--clearBG);
    margin-top: 1rem;
    width: 5rem;
    height: 5rem;
    font-size: 3rem;
    outline: 2px solid var(--disabled);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--disabled);

    :hover{
      cursor: pointer;
      >p{
        display: flex;
        color: var(--fontColor);
      }
    }

    p{
      position: absolute;
      display: none;
      font-size: 1rem;
    }
  }
  
  .group{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .subscribe{

    padding-bottom: 1rem;

    button{
      align-self: start;
      cursor: pointer;
      margin-top: .21rem;
      padding: .2rem 1rem;
      font-size: 1rem;
    
      background: ${props => (props.subs ? 'var(--disabled)' : 'var(--color5)')};
      color: ${props => (props.subs ? 'var(--disabled)' : 'var(--fontColor)')};     
    
      border-radius: 0rem 1rem 1rem ;
    
      :hover{
        color: var(--color3);
      }
   }

  }  

  .userInfo{
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;  
    gap: .1rem;  
    
    .title{
      color: var(--disabled);
    }

    span{
      color: var(--fontColor);
    }
  }  

  .edit{
    /* background-color: #fff; */
    display: flex;
    position: absolute;
    right: .5rem;
    top: 1rem;
    font-size: 2.1rem;
    justify-content: end;
    color: var(--disabled);

    :hover{
      cursor: pointer;
      color: var(--color5);
    }
  }

  .name{
    display: flex;
    align-items: center;
    gap: 1rem;

    label{
      color: var(--disabled);
    }

    input{
      border-radius: .4rem;
      background: var(--disabled);
      padding: 0.3rem;
      color: var(--fontColor);
      font-size: 1.2rem;
    }
    
    span{
      padding: 0.3rem;
      font-size: 1.2rem;
    }
  }

  .savebtn{
    padding: .5rem;
    border-radius: .5rem;
    background: var(--color5);
    /* color: var(--fontColor); */
    color: white;
    
    :hover{
      cursor: pointer;
      color: var(--color3);
    }
  }

  .topics{
    h4{
      padding-bottom: 1rem;
    }
  }  

  .native{
    display: flex;
    gap: 1rem;
  }
  
  .foreign{
    display: flex;
    align-items: center;
    gap: 1rem;
    span{
      font-size: 1.95rem;
      color: var(--color5);
    }
  }

  .language{
    display: flex;
    flex-direction: column;

    gap: 1rem;
    padding-bottom: 1rem;
  }

  .lang{

    :hover{
      cursor: pointer;
      color: orange;
    }
  }
  
  .motherLang{
    text-decoration: underline;
    text-decoration-color: orange;
  }  

  .cardContainer{
    position: relative;
    width: 100%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    gap: .1rem;

    background: var(--clearBG); 
    padding: 1rem;
    border-radius: .3rem;
    box-shadow: 0 2px 4px 1px hsla(0, 0%, 0%, .1);    
  }

  .groups{
    h4{
      padding-bottom: 1rem;
    }
  }
 
  
`;


