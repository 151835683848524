import styled from 'styled-components';

import { useContext } from 'react';
import { authContext } from '../context/authContext';
import { HiLogout } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {

    const { logout } = useContext(authContext);

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <StyledLogout>
            <i onClick={handleLogout}><HiLogout /></i>
        </StyledLogout>
    );
};

const StyledLogout = styled.div`
  
i{
        color: var(--fontColor);
    }

`;