import React, { createContext, useState } from 'react';
import verbList from '../assets/verbList.json';
import { useRef } from 'react';

export const ConjugationContext = createContext();

export function ConjugationProvider({ children }) {

    const [verb, setVerb] = useState(verbList[1]);
    const [verbs, setVerbs] = useState(verbList);
    const [isVerbMoving, setIsVerbMoving] = useState(false);
    const [radom, setRadom] = useState(null);
    const [randomTense, setRandomTense] = useState(null);
    const [randomVerbPos, setRandomVerbPos] = useState(null);
    const [indexList, setIndexList] = useState([]);
    const [done, setDone] = useState(false);
    const [cheat, setCheat] = useState(true);


    const TensesList = {
        "INFINITIVE": 0,
        "PART_PRESENT": 1,
        "PART_PAST": 2,
        "IMPERATIVE": 3,
        "SIMPLE_PRESENT": 4,
        "SIMPLE_PAST": 5,
        "SIMPLE_FUTURE": 6,
        "SIMPLE_CONDITIONAL": 7,
        "PERFECT_PRESENT": 8,
        "PERFECT_PAST": 9,
        "PERFECT_FUTURE": 10,
        "PERFECT_CONDITIONAL": 11
    };

    let refContactPoint = useRef();
    const vref = useRef();
    const verbRef = useRef();

    const tenses = Object.keys(verb.conjugation);

    const getRandomNumber = (max) => {
        let randomNumber = (Math.random() * max);
        return Math.round(randomNumber);
    };

    const searchRandomTense = () => {
        let randomInd = getRandomNumber();
        let randomIndex = getRandomNumber(tenses.length - 1);

        let isAlreadyUsed = indexList.includes(randomIndex);

        if (indexList.length > tenses.length - 1) {
            setDone(true);
            vref.current.innerHTML = '🥳 🎊 💯 ';
            vref.current.style.fontSize = '1.5rem';
            vref.current.style.outline = '1px solid transparent';
            return;
        }

        if (isAlreadyUsed) {
            searchRandomTense();
            return;
        }
        setIndexList(current => [...current, randomIndex]);
        setRandomTense(tenses[randomIndex]);

        console.log('searchRandom :' + randomIndex)
        console.log(indexList)
    };

    const value = {
        verb,
        vref,
        verbRef,
        setVerb,
        tenses,
        refContactPoint,
        isVerbMoving,
        setIsVerbMoving,
        getRandomNumber,
        randomTense,
        setRandomTense,
        searchRandomTense,
        indexList,
        setIndexList,
        cheat,
        setCheat,
        verbs,
        setVerbs,
        TensesList
    };

    return (
        <ConjugationContext.Provider value={value}>
            {children}
        </ConjugationContext.Provider>
    );
}