import styled from 'styled-components';
import { GlobalContext } from '../../../context/GlobalContext';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { PhrasesContext } from '../context/PhrasesContext';
import { authContext } from '../../Authentication/context/authContext';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { BiTag } from 'react-icons/bi';

export const EditForm = () => {

    const {
        language,
        foreignLang,
        selectedLang,
        setActiveApp,
        modal,
        setModal,
        added,
        setAdded
    } = useContext(GlobalContext);

    const { user, userRole, setUser } = useContext(authContext);

    const {
        userPhrases,
        setUserPhrases,
        getUserPhrases
    } = useContext(UserContext);

    const {
        edit,
        setEdit,
        addPhrase,
        deletePhrase,
        updatePhrase,
        selectedPhrase,
        setSelectedPhrase,
        updateToggle,
        refModifPhrase,
        refModifMeaning,
        refModifTags,
        selectedTag
    } = useContext(PhrasesContext);

    const [tag, setTag] = useState(null);


    const saveHandler = () => {
        if (refModifPhrase.current.value == "") return;

        const info = {
            userId: user.uid,
            lang: foreignLang,
            phraseId: selectedPhrase.id,
            phrase: {
                en: [refModifPhrase.current.value],
                es: [refModifMeaning.current.value],
                tags: refModifTags.current.value.includes('🧮') ? "" : [refModifTags.current.value],
            }
        };

        updatePhrase(info);
        getUserPhrases(user, foreignLang);
        setEdit(false);
        setModal(!modal);

        refModifPhrase.current.value = "";
        refModifMeaning.current.value = "";
    };


    useEffect(() => {
        console.log(selectedPhrase);
        refModifTags.current.value = selectedPhrase.tags[0];

    }, [selectedPhrase, updateToggle]);


    return (
        <StyledEditForm>
            <div className='editContainer'>
                <span onClick={() => setModal(!modal)}>❌</span>
                <div className="editGroup">
                    <div className="editInput">
                        <div className="inputsGroup">
                            <input type="text" name="" ref={refModifPhrase} defaultValue={selectedPhrase.en ? selectedPhrase.en : selectedPhrase.foreign} />
                            <div className="meaning">
                                <input type="text" ref={refModifMeaning} defaultValue={selectedPhrase.es ? selectedPhrase.es : selectedPhrase.meaning[0]} />
                            </div>
                            <div className="tags">
                                {/* <div className="tagsList">
                                    {selectedPhrase.tags && selectedPhrase.tags.map((tag, index) => {
                                        return <div className="tag" key={tag}>
                                            <p>{tag ? tag : '🧮'}</p>
                                            <span>x</span>
                                        </div>;
                                    })}
                                </div> */}

                                <div className="addTag">
                                    <input type="text" ref={refModifTags} placeholder='tema' />
                                    <span>📁</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={saveHandler}>Save 👍</button>
            </div>
        </StyledEditForm>
    );
};

const StyledEditForm = styled.div`

.inputsGroup{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .5rem;

        input{
            background: transparent;
            width: 100%;
            display: flex;

            outline: 2px solid var(--disabled);
            color: var(--fontColor);
            font-size: 1.1rem;
        }
    }

    .meaning{
        display: flex;
        gap: .2rem;
    }

    .addTag{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;

        i{
            font-size: 1.5rem;
        }
    }

    .tags{
        display: flex;
        flex-direction: column;
        gap: .5rem;

        span{
            font-size: 1.2rem;            
        }
    }

    .tag{
        position: relative;        
        display: flex;

        span{
            position: absolute;
            top: 0;
            right: 0;
            color: crimson;
            font-weight: bold;
        }
    }
    
`;