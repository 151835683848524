import {
    createContext,
    useEffect,
    useState
} from "react";

import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    sendPasswordResetEmail,
} from 'firebase/auth';

import { auth } from '../services/database';

export const authContext = createContext();

export function AuthProvider({ children }) {   

    const [user, setUser] = useState(null);
    const [userRole, setUserRole] = useState();
    const [loading, setLoading] = useState(true);

    const signup = (email, password) => createUserWithEmailAndPassword(auth, email, password);
    const login = (email, password) => signInWithEmailAndPassword(auth, email, password);
    const logout = () => signOut(auth);
    const loginWithGoogle = () => {
        const googleProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleProvider);
    };
    const resetPassword = (email) => sendPasswordResetEmail(auth, email);    

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            setUser(currentUser);

            setLoading(false);

            if (currentUser) {
                currentUser.getIdTokenResult().then(idTokenResult => {
                    setUserRole(idTokenResult.claims);
                });

            } else {
                setUserRole({});
            }
        });

        return () => unsubscribe();
    }, []);

    const value = {
        signup,
        login,
        user,
        setUser,
        userRole,
        logout,
        loading,
        loginWithGoogle,
        resetPassword
    };

    return (
        <authContext.Provider value={value}>
            {children}
        </authContext.Provider>
    );
}