import styled from 'styled-components';
import { SideBar } from '../components/Nav/SideBar';
import { TopBar } from "../components/Nav/TopBar";
import { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { MdOutlineCloudDownload } from "react-icons/md";
import { UserSync } from '../features/AdminDashboard/Components/UserSync';
import { Collapsible } from '../features/AdminDashboard/Components/Collapsible';
import { LuAlertTriangle } from "react-icons/lu";
import { FaRegChartBar, FaChartPie } from "react-icons/fa";
import { UserData } from '../features/AdminDashboard/Components/UserData';


export const AdminDashboard = () => {

  const {
    getUsersList,
    fbusers,
    setFbusers,
    pgusers,
    setPgusers,
    getPgUsers,
    topics,
    tenses,
    verbs,
    getTopics,
    getTenses,
    getVerbs,
    phrases,
    getPhrases
  } = useContext(UserContext);

  const [fbUpdated, setFbUpdated] = useLocalStorage('fbUpdated', '');
  const [pgUpdated, setPgUpdated] = useLocalStorage('pgUpdated', '');

  const [section, setSection] = useLocalStorage('section', '');


  const dateHandler = () => {
    const date = new Date();
    const time = date.getHours() + 'h' + date.getMinutes();
    const full = date.getDate() + '/' + date.toLocaleString('default', { month: 'numeric' }) + '/' + date.getFullYear();
    return `${time} - ${full}`;
  };

  const dateFbHandler = () => {
    setFbUpdated(dateHandler());
  };

  const updatePgHandler = () => {
    datePgHandler();
    getPgUsers();
  };

  const datePgHandler = () => {
    setPgUpdated(dateHandler());
  };

  const updateFbHandler = () => {
    dateFbHandler();
    getUsersList();
  };



  const verbsHandler = () => {
    getVerbs();
  };

  const topicHandler = () => {
    getTopics();
  };

  const tensesHandler = () => {
    getTenses();
  };

  const phrasesHandler = () => {
    getPhrases();
  };

  useEffect(() => {
    // console.log(section);
  }, [section]);

  return (
    <StyledAdminDashboard>
      <TopBar />

      <div className="dashContainer">
        <h3>Admin Dashboard</h3>

        <div className="board">
          <div className="elements">
            <p onClick={() => { setSection("fbUsers"); }}>Users</p>
            <p onClick={() => { setSection("PgUsers"); }}>PgUsers</p>
            <p onClick={() => { setSection("Stats"); }}>Stats</p>
          </div>

          <div className="infoBoard">

            <div className="boardContainer">
              {section == "fbUsers" &&
                <div className="fbContainer">
                  <div className="title">
                    <p>Firebase Users # {fbusers.length} 🔒</p>
                    <button
                      onClick={updateFbHandler}
                    >
                      <i>
                        <MdOutlineCloudDownload />
                      </i>
                    </button>
                  </div>
                  <div className="fb_users noScrollBar">
                    {fbusers && fbusers.map((user, index) => {
                      let pgUser = '';
                      if (user.customClaims && 'pguser' in user.customClaims) {
                        pgUser = user.customClaims.pguser;
                      }
                      return <div className='userBoard' key={index}>
                        <p>{index + 1}</p>
                        <p>{user.email}</p>
                        <div className="cloud">
                          <UserSync pgUser={pgUser} user={user} />
                        </div>
                      </div>;
                    })}
                  </div>
                </div>
              }

              {section == "PgUsers" &&
                <UserData pgusers={pgusers} />
              }

              {section == "Stats" &&
                <div className="general">
                  <i><FaChartPie /></i>
                  <i><FaRegChartBar /></i>
                </div>
              }
            </div>
          </div>
        </div>

        {/* <div className="collab">
          <Collapsible title={'Users'}
            icon={`🖐️`}
            number={fbusers.length}>
            <div className="title">
              <p><small>{fbUpdated}</small></p>
              <button
                className='btn'
                onClick={updateFbHandler}
              >
                <i>
                  <MdOutlineCloudDownload />
                </i>
              </button>
            </div>
            <div className="usersList">
              {fbusers && fbusers.map((user, index) => {
                let pgUser = '';
                if (user.customClaims && 'pguser' in user.customClaims) {
                  pgUser = user.customClaims.pguser;
                }
                return <div className='user' key={index}>
                  <p>{index + 1}</p>
                  <p>{user.email}</p>
                  <div className="item">
                    <UserSync pgUser={pgUser} user={user} />
                  </div>
                </div>;
              })}
            </div>
          </Collapsible>

          <Collapsible title={'PgUsers'}
            icon={`🔒`}
            number={pgusers.length}>
            <div className="title">
              <p><small>{pgUpdated}</small></p>
              <button
                className='btn'
                onClick={updatePgHandler}
              >
                <i>
                  <MdOutlineCloudDownload />
                </i>
              </button>
            </div>
            <div className="postgresList">
              {pgusers && pgusers.map((user, index) => {

                // const date = new Date(user.created).toLocaleString('en', { month: 'long' });


                return <div className='user' key={index} >
                  <p> {index + 1} </p>
                  <p> {user.email}</p>
                  <i className='alert'><LuAlertTriangle /></i>
                </div>;
              })}
            </div>
          </Collapsible>

          <Collapsible title={'topics'}
            icon={'🧠'}
            number={topics.length}>
            <div className="title">
              <p><small>{pgUpdated}</small></p>
              <button
                className='btn'
                onClick={topicHandler}
              >
                <i>
                  <MdOutlineCloudDownload />
                </i>
              </button>
            </div>
            <div>
              {topics && topics.map(topic => {
                return <p key={topic.topic_id}>{topic.topic}</p>;
              })}
            </div>
          </Collapsible>

          <Collapsible title={'verbs'}
            icon={'🧮'}
            number={verbs.length}>
            <div className="title">
              <p><small>{pgUpdated}</small></p>
              <button
                className='btn'
                onClick={verbsHandler}
              >
                <i>
                  <MdOutlineCloudDownload />
                </i>
              </button>
            </div>
            <div>
              {verbs && verbs.map(verb => {
                return <p key={verb.verb_id}>{verb.verb}</p>;
              })}
            </div>
          </Collapsible>

          <Collapsible title={'tenses'}
            icon={'🔠'}
            number={tenses.length}>
            <div className="title">
              <p><small>{pgUpdated}</small></p>
              <button
                className='btn'
                onClick={tensesHandler}
              >
                <i>
                  <MdOutlineCloudDownload />
                </i>
              </button>
            </div>
            <div>
              {tenses && tenses.map(tense => {
                return <p key={tense.tense_id}>{tense.tense}</p>;
              })}
            </div>
          </Collapsible>

          <Collapsible title={'phrases'}
            icon={'📊'}
            number={phrases.length}>
            <div className="title">
              <p><small>{pgUpdated}</small></p>
              <button
                className='btn'
                onClick={phrasesHandler}
              >
                <i>
                  <MdOutlineCloudDownload />
                </i>
              </button>
            </div>
            <div>
              {phrases && phrases.map(phrase => {
                return <p key={phrase.phrase_id}>{phrase.phrase}</p>;
              })}
            </div>
          </Collapsible>
        </div> */}

      </div>

    </StyledAdminDashboard>

  );
};

const StyledAdminDashboard = styled.div`

    background: var(--bgColor); 
    
    width: 100%;
    display: flex;
    flex-direction: column;     
    
    .dashContainer{
      margin-top: 1rem;
      border-radius: 1rem;
      
      display: flex;
      flex-direction: column;
      gap: .1rem;      
           
      h3{
        align-self: center;
        padding-bottom: 1rem;
      }

      small{
        color: var(--disabled);
        font-size: .9rem;
      }

    }

    .board{
      display: grid;
      grid-template-columns: 1fr;
      padding-bottom: 1rem;
      border-top: 1px solid var(--fontColor);

      
    }

    .elements{
      position: relative;
      display: flex;
      height: 100%;
      /* flex-direction: column;    */

       :hover{

            ::after{
            content: "" ;
            position: absolute;
            width: 0;
            height: 2rem;

            right: 0;
            top: 50%;
            transform: translateY(-50%);
            /* background: crimson; */

            border-right: 4px solid var(--fontColor);
            border-radius: 2px;        

          }   
       }   
      

       p{
        padding: .5rem;

          :hover{
            cursor: pointer;
            background-color: var(--color5);
        }
       } 
    }   

    .infoBoard{
      background-color: hsla(0,0%,100%, .1);
      display: flex;
      padding: .5rem;
      height: 100vh;
    }

    .title{
      /* background: crimson; */
      border-bottom: 2px solid var(--fontColor);
      display: flex;
      align-items: end;
      padding: .1rem ;      
    }

    .collab{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }

    .fb_users{
      display: grid;
      padding-top: 1rem;

      height: 15rem;
      overflow: scroll;
      border-bottom: 1px solid var(--fontColor);
    }

    .userBoard{
      display: grid;
      grid-template-columns: 2rem 1fr 3rem;
      justify-content: space-between;
      gap: 1.1rem;      

      p{
        overflow: hidden;        
      }

      .cloud{
        display: flex;
        justify-self: end;
        gap: 1rem;
        padding-right: .2rem;

        i{
          font-size: 1.5rem;
        }
        
      }  
    }

    .general{
      display: flex;
      gap: 1rem;
    }


    .title{
      display: flex;
      justify-content: space-between;
      button{    
      background-color: transparent;
      color: var(--disabled);
      margin-left: .5rem;
      border-radius: .5rem;
      
      :hover{
        cursor: pointer;

      }

      i{
        padding: .2rem;
        font-size: 1.7rem;

        :hover{
        color: orange;
        
        }

        p{
          font-size: .8rem;
          font-style: normal;
          color: skyblue;
        }
      }
  }
    }

    .usersList{
      width: 100%;
      display: flex;
      flex-direction: column; 
      gap: .1rem;
      
      margin-bottom: 2rem; 
      font-size: 1.2rem; 
    }

    .postgresList{
      width: 100%;
      display: flex;
      flex-direction: column;  
      font-size: 1.2rem; 
    }

    .user{
      display: grid;
      grid-template-columns: 2rem 1fr 3rem;
      justify-content: space-between;
      gap: 1.1rem;      

      p{
        overflow: hidden;
        
      }

      .item{
        display: flex;
        justify-self: end;
        gap: 1rem;
        padding-right: .2rem;
      }      
    }
    
    .btn{
      background-color: transparent;
      color: var(--disabled);
      margin-left: .5rem;
      border-radius: .5rem;
      :hover{
        cursor: pointer;

      }

      i{
        padding: .2rem;
        font-size: 1.5rem;

        :hover{
        color: orange;
        
      }

        p{
          font-size: .8rem;
          font-style: normal;
          color: skyblue;
        }
      }

    }

.userForm{
  input{
    padding-left: .2rem;
  }
}

.alert{
  :hover{
    cursor: pointer;
    color: crimson;
  }
}

.boardContainer{
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: .1rem;
  i{
    font-size: 5rem;
  }
}

.fbContainer{ 
  
  display: flex;
  flex-direction: column;

    button{
      align-self: end;
      background-color: transparent;
      color: var(--disabled);
      /* margin-left: .5rem;
      border-radius: .5rem; */

      :hover{
        cursor: pointer;
      }

    i{
      font-size: 1.6rem;
    }
  }
}



@media all and (min-width: 650px) {
  
  .board{
      display: grid;
      grid-template-columns: 10rem 1fr;
      padding-bottom: 1rem;
    }

  .elements{
      flex-direction: column;   

  }


}

`;
