import styled from 'styled-components';
import { Mode } from './Mode';
import { Temp } from './Temp';
import { Verb } from './Verb';

export const VerbBoard = () => {

  return (
    <StyledVerbBoard >

      <div className="mainContainer">

        <div className="verb" >
          <Verb />
        </div>

        <div className="infinitif">
          <Mode title='infinitif' >
            <Temp title='présent' tense='infinitif_present' />
            <Temp title='passé' tense='infinitif_passe' />
          </Mode>
        </div>

        <div className="imperatif">
          <Mode title='impératif' >
            <Temp title='présent' tense='imperatif_present' />
          </Mode>
        </div>

        <div className="participe">
          <Mode title='participe' >
            <Temp title='présent' tense='participe_present' />
            <Temp title='passé' tense='participe_passe' />
          </Mode>
        </div>

        <div className="conditionnel">
          <Mode title='conditionnel' >
            <Temp title='présent' mode='conditionnel' tense='conditionnel_present' />
            <Temp title='passé' mode='conditionnel' tense='conditionnel_passe' />
          </Mode>
        </div>

        <div className="subjonctif">
          <Mode title='subjonctif' >
            <Temp title='présent' mode='subjonctif' tense='subjonctif_present' />
            <Temp title='passé' mode='subjonctif' tense='subjonctif_passe' />
          </Mode>
        </div>

        <div className="indicatif">
          <Mode title='indicatif'>
            <div className="left">
              <Temp title='présent' tense='indicatif_present' />
              <Temp title='futur simple' tense='indicatif_futurSimple' />
              <Temp title='passé composé' tense='indicatif_passeCompose' />
            </div>
            <div className="right">
              <Temp title='imparfait' tense='indicatif_imparfait' />
              <Temp title='futur proche' tense='indicatif_futurProche' />
              <Temp title='plus-que-parfait' tense='indicatif_plusqueParfait' />
            </div>
          </Mode>
        </div>

      </div>

    </StyledVerbBoard>
  );
};

const StyledVerbBoard = styled.div`

  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
  
  
  .mainContainer{     

        width: 100%;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: .5rem;
        padding: .5rem;

        grid-template-areas:    'indicatif  infinitif   imperatif'
                                'indicatif  verb        participe'
                                'indicatif  subjonctif  conditionnel'
        ;         
    
    }     
    

    .verb{
        grid-area: verb;
        
  -webkit-tap-highlight-color: transparent; /* transparent with keyword */
-webkit-tap-highlight-color: rgba(0,0,0,0); /* transparent with rgba */
-webkit-tap-highlight-color: hsla(0,0,0,0); /* transparent with hsla */
-webkit-tap-highlight-color: #00000000; /* transparent with hex with alpha */
-webkit-tap-highlight-color: #0000; /* transparent with short hex with alpha */

  :focus, :active{
            outline: none !important;

        }
    }    

    .right, .left{        
        width: 100%;
      
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: .5rem;
      }
    
    .indicatif{
        grid-area: indicatif;
    }

    .imperatif{
        grid-area: imperatif;
    }

    .participe{
        grid-area: participe;
    }

    .conditionnel{
        grid-area: conditionnel;
    }

    .subjonctif{
        grid-area: subjonctif;
    }  
  
`;