import React from 'react';
import { IoNotificationsOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const Notification = () => {
    return (
        <StyledNotification>
            <i><IoNotificationsOutline /></i>
        </StyledNotification>
    );
};

const StyledNotification = styled.div`

    i{
        color: whitesmoke;
        position: relative;
        transition: all .4s ease;
        font-size: 1.7rem;

        &::after{
            position: absolute;
            content: '';
            top: .7rem;
            right: .4rem;

            width: .5rem;
            height: .5rem;
            background: var(--color5);
            border-radius: 50%;
        }
        
    }
  
`;
