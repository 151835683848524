import styled from 'styled-components';
import { GlobalContext } from '../../context/GlobalContext';
import { useContext } from 'react';

export const AddButton = () => {

    const {
        showPhraseForm,
        setShowPhraseForm,
        modal,
        setModal,
    } = useContext(GlobalContext);

    const showHandler = () => {
        setShowPhraseForm(!showPhraseForm);
        setModal(false); 
        // console.log('AddButton disabled');
    };

    return (
        <StyledAddButton
            showForm={showPhraseForm}
            onClick={showHandler}
            className='noSelect'>
            <p>+</p>
        </StyledAddButton>
    );
};

const StyledAddButton = styled.div`
    
    position: fixed;
    z-index: 501;
    bottom: 1.5rem;
    right: 1rem;

    width: 3.4rem;
    height: 3.4rem;
    background: var(--color5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    outline: 2px solid var(--disabled);

    p{
        font-size: 1.5rem;
        color: whitesmoke;
        transform: rotate(${props => (props.showForm ? '405deg' : '0deg')});        
        transition: all 1s ease-in-out;
    }

    :hover{
        cursor: pointer;
    }


`;