import styled from 'styled-components';
import { useContext, useId } from 'react';

import { GlobalContext } from '../../../context/GlobalContext';
import { ConjouonsContext } from '../context/ConjouonsContext';

export const Temp = ({ title, mode, tense }) => {

    const { modal, setModal } = useContext(GlobalContext);
    const { setCurrentTense, setInfo } = useContext(ConjouonsContext);

    const id = useId();
    let about = 'tense';

    const modalHandler = () => {
        setCurrentTense(tense);
        setInfo({ about, title });
        setModal(!modal);
    };

    return (
        <StyledTemp
            title={title}
            id={id}
            className='temp'
            onClick={modalHandler}
            data-tense={tense}
        >

        </StyledTemp>
    );
};

const StyledTemp = styled.div`   
cursor: pointer;
        width: 100%;
        min-width: 2rem;
        height: 2rem;

        background: var(--alphaBg);
        color: var(--fontColor);
        border-radius: .5rem;

        display: flex;
        justify-content: start;
        
        box-shadow: .1rem .1rem 2rem .1rem hsla(0,0%, 0%, .05);

        /* transition: all .8s ease-in-out;                */
         
        cursor: pointer;

        outline: none; 

        overflow: hidden;

        .content{
            width: 100%;
            height: 100%;
        }

`;