import styled from 'styled-components';
import { useContext } from 'react';

import { GlobalContext } from '../context/GlobalContext';
import { GiYinYang } from "react-icons/gi";
import { UserContext } from '../context/UserContext';



export const LangToggle = () => {

    const {
        language,
        foreignLang,
        langToggle,
        setLangToggle,
        selectedLang,
        setSelectedLang
    } = useContext(GlobalContext);

    const {
        userPhrases,
        setUserPhrases,
        getUserPhrases
    } = useContext(UserContext);

    const langHandler = () => {
        setLangToggle(!langToggle);
        let lang = langToggle ? foreignLang : language;
        setSelectedLang(lang);
        yangRotator();
    };

    function yangRotator(){
        let yang = document.getElementById('yang');        
        yang.style.rotate = langToggle ? '180deg' : '-360deg';
    }

    return (
        <StyledLangToggle>            
            <GiYinYang id='yang' onClick={langHandler} />           
        </StyledLangToggle>
    );
};

const StyledLangToggle = styled.div`
    
    cursor: pointer;

    #yang{
        /* color: var(--fontColor);   */
        color: hsla(0,0%,100%,.5);      
        font-size: 1.2rem; 

        transition: 1s ease-in-out;        
    }  
  
`;
