import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { PhrasesContext } from '../context/PhrasesContext';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

export const PhrasesCounter = ({ tagFilter }) => {

    const {
        userPhrases,
        phrasesByTag,
        selectedTag
    } = useContext(UserContext);

    const {
        filteredPhrases
    } = useContext(PhrasesContext);

    const [filterCount, setFilterCount] = useState(0);

    function filterCounterHandler() {
        let filterCount = 0;
        filteredPhrases.map(phrase => {
            if (phrase) filterCount += 1;
        });
        setFilterCount(filterCount);
    } 

    useEffect(() => {
        filterCounterHandler();
    }, [filteredPhrases]);

    return (
        <StyledPhrasesCounter>

            <div className="numberContainer">
                {selectedTag == '🧮' ?
                    <p className='number'>{`${filterCount} / ${userPhrases.length}`}</p>
                    :
                    <p className='number'>{`${filterCount} / ${phrasesByTag[selectedTag]}`}</p>
                }
            </div>

        </StyledPhrasesCounter>
    );
};

const StyledPhrasesCounter = styled.div`
    /* background: red; */
    display: flex;
    justify-content: center;
`;