import styled from 'styled-components';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { useState } from 'react';
import { BiTag } from 'react-icons/bi';
import { FiEdit3 } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';


export const PhraseGroup = ({ es, en, starNum, ranked, tag }) => {

    const [stars, setStars] = useState(starNum);
    const [star, setStar] = useState(ranked);
    const [isActive, setIsActive] = useState(false);

    const starHandler = () => {
        if (!star) {
            setStars(stars + 1);
            setStar(!star);
            return;
        }

        if (star) {
            setStars(stars - 1);
            setStar(!star);
            return;
        }
    };

    const activeHandler = () => {
        setIsActive(!isActive);
    };

    return (
        <StyledPhraseGroup
            className='noSelect'
            active={isActive}
        >
            <div className="header">
                <div className="phrases">
                    <p className='lang1'>{es}</p>
                    <p className='lang2'>{en}</p>
                </div>
                <div className="info">
                    <div className="tag">{tag}
                        <i><BiTag /></i>
                    </div>
                    <div className="stars icon">
                        <p className='number' onClick={starHandler}>{stars}
                            {star ? <AiFillStar className='star ranked' /> : <AiOutlineStar className='star ' />}
                        </p>
                    </div>
                </div>
            </div>
            <div className="more noScrollBar" >


                <div className="content">
                    {/* <div className="contentHeader">
                        <h3>Title</h3>
                        <p>Playground</p>
                    </div> */}
                    <div className="elements">
                        <div className="gifArea">
                            <p>+ gif</p>
                        </div>
                        <div className="gifArea">
                            <p>+ book</p>
                        </div>
                        <div className="figures">
                            <div className="square"></div>
                            <div className="triangle"></div>
                            <div className="figure"></div>
                        </div>
                    </div>
                </div>

                <div className="moreIcon" onClick={activeHandler}>
                    {isActive ?
                        <IoIosArrowUp className='' />
                        :
                        <IoIosArrowDown className='' />
                    }
                </div>
            </div>
        </StyledPhraseGroup>
    );
};



const StyledPhraseGroup = styled.div`

background: var(--clearBG);
/* background: white; */
padding: .5rem 1rem .1rem 1rem;
font-size: 1.2rem;

display: flex;
flex-direction: column;

cursor: pointer;
overflow: hidden;
height: ${props => (props.active ? '12rem' : '6rem')};

transition: height .4s ease-in-out;

border-radius: 1rem 1rem 1rem 1rem;
position: relative;

.header{
    /* background: green; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    position: relative;
    padding-bottom: 1rem;
    
    /* ::after{
        content: '';
        position: absolute;
        background: hsla(0,0%, 0%,.1);
        width: 70%;
        height: 2px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1rem;
    } */
}

.phrases{
    /* background: red; */
    display: flex;
    flex-direction: column;    
}

.info{
    /* background: green; */
    display: flex;
    flex-direction: column;
    gap: .5rem;
    
}

.tag{    
    /* background: green; */
    display: flex;
    justify-content: end;    
    align-items: center;
    /* height: 5rem; */
    gap: .2rem;
    
    /* color: hsla(0,0%,100%,.1); */
    color: var(--disabled);
    /* font-family: 'Fredoka', sans-serif; */
    /* font-family: 'Quicksand', sans-serif; */
    /* font-family: 'Comfortaa', cursive; */
    /* font-family: 'Poiret One', cursive; */

    i{
        /* background: green; */
        display: flex;
        align-items: center;
        
        /* transform: rotate(-90deg); */
    }
}

.lang1{
    /* color: skyblue; */
    color: var(--color5);
    /* color: hsla(0,0%,100%,.5); */
}

.lang2{
    /* color: skyblue; */
    /* color: hsla(0,0%,100%,.5); */
}

.icon{
    color: var(--disabled);
    
}

.stars{
    display: flex;
    justify-content: end;
    gap: 1rem;

    p{
        display: flex;
        gap: .2rem;
    }
}

.number{
    font-size: 1rem;    
}

.star{
font-size: 1.2rem;
}

.ranked{
    color: hsla(49, 100%, 50%, 1);
}

.loved{
    color: red;
}

.edit{
    display: flex;
    justify-content: end;
    /* color: hsla(0,0%,100%,.1);     */
    color: var(--disabled);    
    font-size: 1.3rem;
}

.more{
    /* background: green; */
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    /* color: hsla(0,0%,100%,.2);     */
    color: var(--disabled); 
    
    overflow-x: scroll;
    
}

.moreIcon{
    background: var(--clearBG);
    /* background: hsla(49, 98%, 19%, 1); */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: start;
    align-self: end;  
    padding: .5rem 1rem .1rem 1rem;
    transition: all 2s ease-in-out;
    /* box-shadow: ${props => (props.active ? '0rem 0rem 2rem .2rem #03ffea37' : 'none')}; */
}

.content{
    /* background: crimson; */
    display: flex;
    overflow: hidden;
    
    font-size: 1rem;
}

.elements{
    /* background: orange; */
    max-width: 19rem;
    display: flex;
    
    overflow: scroll;
    gap: 1rem;
    padding-bottom: 1rem;
}

.gifArea{
    /* width: 100%;     */
    min-width: 6rem;
    display: flex;
    justify-content: center;
    border: 2px dashed var(--disabled);
    border-radius: 1rem;
    padding: 1.5rem; 
    
    :hover{
        border: 2px dashed var(--color5);
    }

    p{
        white-space: nowrap;

        :hover{
            color:var(--fontColor);
        }
    }
}

.figures{
    /* background: green; */
    display: flex;
    justify-items: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;
}

.square{
    /* background: var(--disabled); */
    width: 3rem;
    height: 3rem;
    border-radius: 1rem;
    border: 2px solid var(--disabled);
    :hover{
        background-color: crimson;
    }
}

.triangle{
    /* background: var(--disabled); */
    width: 3rem;
    height: 3rem;
    border-radius: 1rem 12rem 5rem 12rem;
    border: 2px solid var(--disabled);

    :hover{
        background-color: yellowgreen;
    /* border: 2px solid hsla(49, 100%, 50%, 1); */

    }
}

.figure{
    /* background: var(--disabled); */
    width: 3rem;
    height: 3rem;
    border-radius: 1rem 0rem 5rem 5rem;
    border: 2px solid var(--disabled);

    :hover{
        background-color: darkmagenta;
    /* border: 2px solid hsla(299, 100%, 50%, 1); */

    }
}


    
`;