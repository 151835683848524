import { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../context/GlobalContext';

export const Modal = ({ children }) => {

    const { modal, setModal } = useContext(GlobalContext);

    const modalHandler = () => {
        setModal(!modal);
    };

    return (
        <StyledModal show={modal} >
            <div className="modalBg" onClick={modalHandler} ></div>
            <div className="modalContent">
                {children}
            </div>
        </StyledModal>
    );
};

const StyledModal = styled.div`

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
    overflow: hidden;

    display: ${props => (props.show ? 'grid' : 'none')};
    grid-template-columns: 1fr;
    grid-template-areas: 'modal';    

    .modalBg{  
        grid-area: modal;
        width: 100%;     
        background: var(--bgColor);
        background: hsla(0, 0%, 0%, 0.9);
        /* background: green; */
        opacity: .8;
        z-index: 100;
    }
    
    .modalContent{
        width: 100%;
        z-index: 101;
        grid-area: modal;
        justify-self: center;
        align-self: center;
        align-items: start;
        
        /* background: blue; */
        display: flex;
        flex-direction: column;

        padding: 1rem .3rem;
        padding-bottom: 5rem;

    }

`;