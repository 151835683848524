import styled from 'styled-components';
import { BsPlus } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
import { PhraseGroup } from './PhraseGroup';
import { useState, useContext } from 'react';
import { PhrasesContext } from '../context/PhrasesContextOld';
import { useEffect } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { Modal } from '../../../components/Modal';
import { PhraseForm } from './PhraseForm';

export const Phrases = () => {

   const { modal, setModal } = useContext(GlobalContext);

   const {
      tag,
      word,
      setWord,
      filteredPhrases,
      showPhrases,
      setShowPhrases,
      wordFilter,
      phraseSearch,
   } = useContext(PhrasesContext);


   const closeHandler = () => {
      setWord(null);
      // console.log('nullword');
   };


   useEffect(() => {
      if (word || tag) {
         setShowPhrases(true);
      } else {
         setShowPhrases(false);
      }

      wordFilter();
      // console.log('word:', word);
      // console.log('tag:', tag);
      phraseSearch();
   }, [word, tag]);


   const addHandler = () => {
      setModal(!modal);
   };

   return (
      <StyledPhrases>

         <div className="content">
            {word &&
               <div className="research">
                  <p >{word}</p>
                  <CgCloseO onClick={closeHandler} className="closeIcon" />
               </div>
            }

            <div className="list">
               {showPhrases && filteredPhrases.map((phrase) => {
                  return <PhraseGroup
                     key={phrase.id}
                     ranked={phrase.star}
                     es={phrase.phrases.es}
                     en={phrase.phrases.en}
                     starNum={phrase.stars}
                     tag={phrase.tags[0]}
                  />;
               })}
            </div>

         </div>

         <div className="add" onClick={addHandler}>
            <BsPlus />
         </div>

         <Modal>
            <PhraseForm />
         </Modal>

      </StyledPhrases>
   );
};

const StyledPhrases = styled.div`
   width: 100%;
   /* max-width: 30rem; */
   /* background: green;         */
    

   .content{
      gap: 1rem;
   }

   .list{
      display: flex;
      flex-direction: column;
      gap: 1rem;
   }

   .add{
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background: var(--color5);
      font-size: 3rem;
      box-shadow: .1rem .1rem 1rem .5rem hsla(0,0%,100%,.1);
   }   
   
   .research{
      display: flex ;
      padding: .1rem;
      
      p{      
         padding: 0.5rem;
         font-size: 1.2rem;
         font-weight: bold;
         color: var(--color5);
         
      }

      .closeIcon{         
         background: transparent;
         color: var(--disabled);
         font-size: 1.3rem;

         cursor: pointer;
         :hover{
            color: whitesmoke;
         }
      }

   }
   

`;