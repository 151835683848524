import styled from 'styled-components';

import { UserContext } from '../../../context/UserContext';
import { useContext } from 'react';
import { MdOutlineCloudDownload } from "react-icons/md";
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useState } from 'react';
import { useEffect } from 'react';

export const UserData = ({ pgusers }) => {

    const {        
        getPgUsers,
        getPhrases,
        phrases
    } = useContext(UserContext);

    const [selectedUser, setSelectedUser] = useLocalStorage('selectedUser', '');
    const [userSection, setUserSection] = useLocalStorage('userSection', '');
    const [showUsers, setShowUsers] = useState(false);

    const updatePgHandler = () => {
        // datePgHandler();
        getPgUsers();
    };

    const selectHandler = () => {
        setShowUsers(!showUsers);
    };

    const showHandler = () => {
        setShowUsers(!showUsers);
        //  console.log(showUsers)   
    };

    useEffect(() => {
        //   console.log(userSection)
        console.log(phrases)
        console.log(pgusers)
    }, [userSection])
    

    return (
        <StyledUserData show={showUsers}>
            <div className="title">
                <p>Postgres Users # {pgusers.length}🧑‍🚀</p>
                <button
                    onClick={updatePgHandler}
                >
                    <i>
                        <MdOutlineCloudDownload />
                    </i>
                </button>
            </div>
            <div className="uselect">
                <p
                    className='selectedPg'
                    onClick={showHandler}
                >{selectedUser && selectedUser}</p>
                <p
                    onClick={showHandler}
                >🔽</p>
            </div>
            <div className="content">

                <div className="pgUserList noScrollBar"
                    onClick={selectHandler}>
                    {pgusers && pgusers.map((user, index) => {

                        return <div className='pguser' key={index} >
                            <p> {index + 1}. </p>
                            <p onClick={() => { setSelectedUser(user.email); }}> {user.email}</p>
                        </div>;
                    })}
                </div>
            </div>
            <div className="options">
                <p
                    onClick={() => { setUserSection('phrases'); }}>Phrases</p>
                <p
                    onClick={() => { setUserSection('topics'); }}>Topics</p>
                <p
                    onClick={() => { setUserSection('verbs'); }}>Verbs</p>
                <p
                    onClick={() => { setUserSection('stats'); }}>Stats</p>               
            </div>
            <div className="info">
                {userSection == 'phrases' && 
                    <div className="userPhrases">
                        <div>
                            {phrases && phrases.map((phrase, index) => {
                                
                                let uid = selectedUser.uid;

                                if (phrase.uid !== uid) return

                                return <div className="selphrases"
                                    key={phrase.phrase_id}>
                                    <p>{index + 1}.</p>
                                    <p>{uid}</p>
                                    <p >{phrase.phrase}</p>
                                </div>
                            })}
                        </div> 
                </div>
                }
                {userSection == 'topics' && 
                    <div className="userTopics">
                        <p>✈️</p> 
                </div>
                }
                {userSection == 'verbs' && 
                    <div className="userVerbs">
                        <p>🧮</p> 
                </div>
                }
                {userSection == 'stats' && 
                    <div className="userStats">
                        <p>📊</p> 
                </div>
                }
            </div>
        </StyledUserData>
    );
};

const StyledUserData = styled.div`

  width: 100%;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  .pguser{
  
  display: flex;
  gap: 1rem;
  :hover{
    cursor: pointer;
  }
}

.selectedPg{
  font-weight: bold;
}
  
.options{
  width: 100%;
  display: flex;
  gap: 1rem;
  /* justify-content: space-between; */
  /* background-color: crimson; */

  p{

    :hover{
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: var(--color5);
      text-decoration-thickness: 2px;
    }
  }
}

.uselect{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
}

.content{
    /* background: crimson; */
     height: ${props => (props.show ? '10rem' : '0')};
     overflow: hidden;
     transition: height 1s ease;
     border-bottom: 1px solid var(--fontColor);
     /* padding-bottom: 1rem; */
}

.pgUserList{
    height: 10rem;
    overflow: scroll;
}

.info{

    height: 5rem;
    
}

.userPhrases{
    /* background: crimson; */
    font-size: 1.1rem;
}

.selphrases{
    display: flex;
    gap: 1rem;
}

    
`;