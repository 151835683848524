import styled from 'styled-components';


export const Alert = ({ message, bgColor, anim, stay }) => {
    return (
        <StyledAlert bgColor={bgColor} anim={anim} stay={stay} >
            <span>{message}</span>
        </StyledAlert>
    );
};

const StyledAlert = styled.div`

    z-index: 2000;

    background: ${props => (props.bgColor ? props.bgColor : 'crimson')};
    border-radius: 1rem;
    padding: 1rem;    
    opacity: ${props => (props.stay ? '1' : '0')};

    color: white;
    transition: opacity 1s ease-in-out;

    /* animation-name: alerta; */
    animation-name: ${props => (props.anim ? 'alerta' : 'none')};
    animation-duration: 5s;
    animation-delay: .2s;
    animation-iteration-count: 1;

    @keyframes alerta {
        0% {opacity: 0}
        50% {opacity: 1}
        100% {opacity: 0}        
    }

  
`;