import styled from 'styled-components';
import { SideBar } from '../components/Nav/SideBar';
import { TopBar } from '../components/Nav/TopBar';
import { AppBar } from '../components/Nav/AppBar';
import { GlobalContext } from '../context/GlobalContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { AddButton } from '../components/Nav/AddButton';

export const Feed = () => {

    const {
        language,
        setLanguage,
        langToggle,
        setLangToggle,
        setActiveApp
    } = useContext(GlobalContext);

    useEffect(() => {
        setActiveApp('feed');

    }, []);

    return (
        <StyledFeed>
            <TopBar />
            <AppBar />
            <SideBar />
            <AddButton />

            <div className="feedContainer">
                <div className="feedHeader">
                    <h3>News</h3>
                    <h3> 🧮 🗞️ 🛰️ 🧑‍🚀 ✈️ ⚗️ 🍱</h3>
                    <p>Search <span>Q</span> ...coming soon</p>
                </div>

                <div className="mainFeed">
                    <div className="package">
                        <div className="packageHeader">
                            <h4>Actividad pública</h4>
                            <span>👍</span>
                        </div>
                        <div className='packageContent'>
                            <p>Here, you can explore a variety of public information on several topics that could capture your interest.</p>
                            <p>Feel free to share your own content and see if others appreciate it enough to incorporate it into their own studies.</p>
                        </div>
                    </div>

                    <div className="package">
                        <div className="packageHeader">
                            <h4>Vocabulary</h4>
                            <span>🌋</span>
                        </div>
                        <div className='packageContent'>
                            <p>Some useful words when talking about technology :</p>
                            <p>Framework, cutting-edge technology, artificial intelligence.</p>
                        </div>
                    </div>
                </div>
            </div>
        </StyledFeed>
    );
};

const StyledFeed = styled.div`

width: 100%;
height: 100%;
padding-bottom: 5rem;

display: flex;
flex-direction: column;
justify-content: start;


.feedContainer{
    max-width: 30rem;
    align-self: center;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;    
}

.feedHeader{
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 2rem;
    h3{
        color: var(--color5);
        padding-bottom: 1rem;
    }

    span{
        font-size: 1.5rem;
        font-family: 'Comfortaa', cursive; 
    }
}

.mainFeed{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.package{
    /* background: hsla(0,0%,100%, .1);     */
    background: var(--clearBG) ;
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: 0 2px 4px 1px hsla(0, 0%, 0%, .1); 

    .packageHeader{
        /* background: hsla(0,0%,100%, .1); */
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid var(--disabled);
        h4{
            padding-bottom: 1rem;
            padding: .1rem 1rem;
        }
        span{
            padding: .3rem;
            font-size: 1.2rem;
        }
    }

    .packageContent{
        padding: 1rem;
        
    }
}

`;
