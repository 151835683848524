import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useContext } from 'react';
import { authContext } from '../../Authentication/context/authContext';
import { GlobalContext } from '../../../context/GlobalContext';
import { BiSend, BiTag } from 'react-icons/bi';

export const AddForm = () => {

    const {
        language,
        foreignLang,
        selectedLang,
        setActiveApp,
        modal,
        setModal,
        added,
        setAdded,
        refAlert,
        refTags
    } = useContext(GlobalContext);

    const { user } = useContext(authContext);

    const {
        userPhrases,
        setUserPhrases,
        addPhrase,
        selectedTag
    } = useContext(UserContext);

    const refPhrase = useRef();
    const refMeaning = useRef();

    const addPhraseHandler = async () => {

        if (refPhrase.current.value == "") return;

        const date = new Date().toISOString();

        const phrase = {
            phrase: refPhrase.current.value,
            uid: user.uid,
            language: "english",
            created: date
        };

        addPhrase(phrase);
        playAddAnimation();

        refPhrase.current.value = "";
        refMeaning.current.value = "";
        // refTags.current.value = "";

    };

    function playAddAnimation() {

        refAlert.current.animate(
            [
                { opacity: '1', offset: 0.3 },
                { height: '4rem' }

            ],
            { duration: 3000 });

    }

    useEffect(() => {


    }, [selectedTag]);


    return (
        <StyledAddForm >
            <div className="phaseInput">


                <div className="inputs">
                    {/* <p>temporalmente indisponible</p> */}
                    <div className="groupInput">

                        <input type="text" name="" ref={refPhrase} placeholder='Write your phrase here...' />
                        <i onClick={addPhraseHandler}><BiSend /></i>
                    </div>
                    <div className="meaning">
                        <input type="text" name="" ref={refMeaning} placeholder='Escribe aquí el significado...' />
                        <span>+</span>
                    </div>
                    <div className="addTag">
                        <input type="text" ref={refTags} placeholder='tema' defaultValue={selectedTag} />
                        <span>📁</span>
                    </div>
                </div>
            </div>
        </StyledAddForm>
    );
};

const StyledAddForm = styled.div`

/* background-color: blue; */
display: flex;
flex-direction: column;

.phaseInput{
    width: 100%;
    display: flex;
    /* padding-top: 1rem; */
    
    gap: .5rem;

    input{
        width: 100%;
        gap: 1rem;
        padding: .2rem .5rem;
        border-radius: .5rem;
    }
    p{
        color: red;
    }

    }   

    .groupInput{
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        i{
            font-size: 1.2rem;
            color: var(--color5);
        }
    }     

    
    input{
        background: transparent;
        
        outline: 2px solid var(--disabled);
        color: var(--fontColor);
        font-size: 1.1rem;
    }        

    .inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    }

    .meaning{
        display: flex;
        gap: 1rem;
        span{
            font-size: 1.6rem;
            font-weight: bold;
            padding: .1rem;
            color: var(--color5);
        }
    }

    .addTag{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        padding-top: .6rem;

        i{
            font-size: 1.5rem;
        }
    }
    
`;