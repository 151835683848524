import React, { createContext, useState } from 'react';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

export const PhrasesContext = createContext();

export function PhrasesProvider({ children }) {

    const location = 'https://qbelex-api-bypa6cepfa-uc.a.run.app';
    //const location = 'http://localhost:5000';


    const [userPhrases, setUserPhrases] = useLocalStorage('userPhrases', '');

    const getUserPhrases = async ({ uid }) => {

        try {
            const res = await fetch(`${location}/phrases/${uid}`);
            const data = await res.json();
            setUserPhrases(data);

        } catch (error) {
            console.log(error);
        }
    };

    const value = {
        userPhrases,
        setUserPhrases,
        getUserPhrases,
    };

    return (
        <PhrasesContext.Provider value={value}>
            {children}
        </PhrasesContext.Provider>
    );
}