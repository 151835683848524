import { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../../../context/GlobalContext';
import { ConjouonsContext } from '../context/ConjouonsContext';

export const Mode = ({ children, title }) => {

    const { modal, setModal } = useContext(GlobalContext);
    const { setInfo } = useContext(ConjouonsContext);

    let about = "mode";

    const modalHandler = () => {
        setInfo({ about, title });
        setModal(!modal);
    };

    return (
        <StyledMode title={title} >
            <div className="titre" onClick={modalHandler}>
                <h3>{title}</h3>
            </div>
            <div className="content">
                {children}
            </div>

        </StyledMode>
    );
};


const StyledMode = styled.div`
cursor: pointer;
width :100% ;
height: 100%;

background: var(--alphaBg); 

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        gap: .5rem .2rem;   

        border-radius: .3rem;        
        padding: .5rem 0rem;


    .titre{
        width: 100%;
        
        display: flex;
        justify-content: center;
        
        h3{
            background: hsla(0, 0%, 100%, .05);
            border-radius: .2rem;
            padding: 0 .5rem;
        }
        
    }
    
    .content{
        /* background: hsla(0, 0%, 100%, .05); */       

        width: 100%;
        height:72%;

        display: flex;
        justify-content: center; 
        align-content: center;
        gap: .5rem;
        padding: .5rem;
        

    } 
    

  
`;