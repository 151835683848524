
import styled from 'styled-components';

import { useContext, useState } from 'react';
import { ConjouonsContext } from '../context/ConjouonsContext';
import { Pronoun } from './Pronoun';

export const FullTense = ({ tense }) => {

    const { currentVerb, currentTense } = useContext(ConjouonsContext);
    const conjugation = currentVerb.conjugation[`${currentTense}`];

    console.log('currentTense', currentTense);
    console.log('conjugation', conjugation);

    let counter = -1;

    return (
        <StyledFullTense >
            <p>{tense}</p>

            {conjugation.map((pronoun) => {
                counter += 1;
                return <Pronoun key={pronoun} placeh={conjugation[counter]} />;
            })}


        </StyledFullTense>
    );
};


const StyledFullTense = styled.div`
  
 background: var(--bgColor);

 border-radius: 1rem;
 padding: 1rem;

 display: flex;
 flex-direction: column;
 gap: 1rem;

`;