import { useEffect } from 'react';
import styled from 'styled-components';
import { ConjugationContext } from '../context/ConjugationContext';
import { useContext } from 'react';
import { Tense } from './Tense';
import { GlobalContext } from '../../../context/GlobalContext';

export const BoardConj = () => {

    const {
        selectedLang,

    } = useContext(GlobalContext);

    const {
        verb,
        tenses
    } = useContext(ConjugationContext);


    const infPres = { ...verb.conjugation[tenses[0]] };
    const partPres = { ...verb.conjugation[tenses[1]] };
    const partPast = { ...verb.conjugation[tenses[2]] };
    const imperative = { ...verb.conjugation[tenses[3]] };
    const simplePres = { ...verb.conjugation[tenses[4]] };
    const simplePast = { ...verb.conjugation[tenses[5]] };
    const simpleFuture = { ...verb.conjugation[tenses[6]] };
    const simpleConditional = { ...verb.conjugation[tenses[7]] };
    const perfectPres = { ...verb.conjugation[tenses[8]] };
    const perfectPast = { ...verb.conjugation[tenses[9]] };
    const perfectFuture = { ...verb.conjugation[tenses[10]] };
    const perfectConditional = { ...verb.conjugation[tenses[11]] };


    return (
        <StyledBoardConj>

            <div className="block">
                <div className="infinitive">
                    <h4>Infinitive</h4>
                    <div className="tenseContainer infPres">
                        <p>{`${infPres[selectedLang][0][0]}`}</p>
                    </div>
                </div>

                <div className="participle">
                    <h4>Participle</h4>
                    <div className='mode'>
                        <div className="tenseContainer partPres">
                            <p className='tenseTitle'>present</p>
                            <p>{`${partPres[selectedLang][0][0]} `}</p>
                        </div>
                        <div className="tenseContainer partPast" >
                            <p className='tenseTitle'>past</p>
                            <p>{`${partPast[selectedLang][0][0]}`}</p>
                        </div>
                    </div>
                </div>

                <div className="tenseContainer imperative">
                    <h4>Imperative</h4>
                    <div className="mode">
                        <div className="imperative">
                            <p>{`${imperative[selectedLang][0][0]}`}</p>
                            <p>{`${imperative[selectedLang][0][1]}`}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="simple">
                <h4>Simple</h4>
                <div className="modeSimple">
                    <div className="tenseContainer simplePres">
                        <p className='tenseTitle'>present</p>
                        <p>{`${simplePres[selectedLang][0][0]}`}</p>
                    </div>

                    <div className="tenseContainer simplePast">
                        <p className='tenseTitle'>past</p>
                        <p>{`${simplePast[selectedLang][0][0]}`}</p>
                        {selectedLang == "es" &&
                            <p>{`${simplePast[selectedLang][1][0]}`}</p>}
                    </div>

                    <div className="tenseContainer simpleFuture">
                        <p className='tenseTitle'>future</p>
                        <p>{`${simpleFuture[selectedLang][0][0]}`}</p>
                    </div>

                    <div className="tenseContainer simpleConditional">
                        <p className='tenseTitle'>conditional</p>
                        <p>{`${simpleConditional[selectedLang][0][0]}`}</p>
                    </div>
                </div>
            </div>

            <div className="perfect">
                <h4>Perfect</h4>
                <div className="modePerfect">
                    <div className="tenseContainer perfectPres">
                        <p className='tenseTitle'>present</p>
                        <p>{`${perfectPres[selectedLang][0][0]}`}</p>
                    </div>

                    <div className="tenseContainer perfectPast">
                        <p className='tenseTitle'>past</p>
                        <p>{`${perfectPast[selectedLang][0][0]}`}</p>
                        {selectedLang == "es" &&
                            <p>{`Si ${perfectPast[selectedLang][1][0]}`}</p>}
                    </div>

                    <div className="tenseContainer perfectFuture">
                        <p className='tenseTitle'>future</p>
                        <p>{`${perfectFuture[selectedLang][0][0]}`}</p>
                    </div>

                    <div className="tenseContainer perfectConditional">
                        <p className='tenseTitle'>conditional</p>
                        <p>{`${perfectConditional[selectedLang][0][0]}`}</p>
                    </div>
                </div>
            </div>

        </StyledBoardConj>
    );
};

const StyledBoardConj = styled.div`     
    
    display: flex;
    padding: 1rem 1rem .5rem .5rem;
    gap: 1rem;

    .block{
      grid-area: block;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-right: 1rem;
      border-right: 1px solid var(--fontColor);
    } 

    .perfect{
        display: flex;
        flex-direction: column;
    }    

    .mode{
      display: flex;
      flex-direction: column;
    }

    .tenseTitle{
      font-style: italic;
      opacity: .3;
    }

    .tenseContainer{
        padding-bottom: .7rem;
    }     
`;