import styled from 'styled-components';
import { useState, useContext } from 'react';
import { ConjouonsContext } from '../features/Conjouons/context/ConjouonsContext';
import { TopBar } from '../components/Nav/TopBar';
import { VerbBoard } from '../features/Conjouons/components/VerbBoard';
import { Modal } from '../components/Modal';

import { Info } from '../features/Conjouons/components/Info';

export const Conjouons = () => {
 
  const {
    isVerbMoving,
    setIsVerbMoving,
    score,
    setScore,
    contactPoint,
    randomTense,
    randomVerb,
    searchRandomTense,
  } = useContext(ConjouonsContext);

 
  const [tempID, setTempID] = useState(null);
  const [selectedTemp, setSelectedTemp] = useState('');

  let tempArray = Array.from(document.querySelectorAll('.temp'));

  const getSelectedTemp = (tempList) => {
    tempList.map((temp) => {
      let element = document.getElementById(temp.id);

      if (temp.id === tempID) {
        element.style.outline = '1px dashed var(--fontColor)';
      } else {
        element.style.outline = 'none';
      }

      if (temp.isOverlapping) {
        setTempID(temp.id);
        const selectedTense = element.getAttribute('data-tense');
        setSelectedTemp(selectedTense);
      }
      return element;
    });
  };

  const checkTemp = () => {
    let lastSelected = document.getElementById(tempID);

    if (tempID !== null) {
      if (selectedTemp === randomTense) {
        lastSelected.style.background = 'yellowgreen';

        searchRandomTense();

      } else {
        lastSelected.style.background = 'crimson';
      }
    }
  };

  const arrayOverlaps = (verb, list) => {
    const rect1 = verb.getBoundingClientRect();

    let collisionList = list.map((temp) => {
      const rect2 = temp.getBoundingClientRect();
      const isInHoriztonalBounds = rect1.x < rect2.x + rect2.width && rect1.x + rect1.width > rect2.x;
      const isInVerticalBounds = rect1.y < rect2.y + rect2.height && rect1.y + rect1.height > rect2.y;
      const isOverlapping = isInHoriztonalBounds && isInVerticalBounds;
      let id = temp.getAttribute('id');
      return {
        id: id,
        isOverlapping: isOverlapping
      };
    });
    return collisionList;
  };

  const resetVerbPosition = (e) => {
    const FINGER_SPACE_X = 20;
    const FINGER_SPACE_Y = 65;
    let posX = e.clientX - FINGER_SPACE_X;
    let posY = e.clientY - FINGER_SPACE_Y;
    randomVerb.style.top = `${posY}px`;
    randomVerb.style.left = `${posX}px`;
  };

  const cleanSelection = () => {
    if (tempID !== null) {
      let lastSelected = document.getElementById(tempID);
      lastSelected.style.outline = 'none';
      setTempID(null);
    }
  };

  const pointerDownHandler = (e) => {
    resetVerbPosition(e);
  };

  const pointerMoveHandler = (e) => {

    if (isVerbMoving) {
      resetVerbPosition(e);

      const tempList = arrayOverlaps(contactPoint.current, tempArray);
      getSelectedTemp(tempList);
    }

  };

  const pointerUpHandler = () => {
    checkTemp();
    cleanSelection();

    setIsVerbMoving(false);
    setScore({ ...score, playing: false });
  };
  

  return (
    <StyledConjouons
      onPointerDown={pointerDownHandler}
      onPointerMove={pointerMoveHandler}
      onPointerUp={pointerUpHandler}>

      <VerbBoard />

      <TopBar />
      <Modal>
        <Info />
      </Modal>
    </StyledConjouons>
  );
};

const StyledConjouons = styled.div` 
      -webkit-user-select: none; 
      -ms-user-select: none; 
      user-select: none;  
      touch-action: none;
     
      margin-top: 3rem;  
      width: 100vw ;
      height: 100vh;

      display: flex;
      flex-direction: column;
      align-items: center;

`;
