import styled from 'styled-components';
import { BsBookmarkPlus, BsCheck } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useState, useRef, useEffect, useContext } from 'react';
import { Alert } from '../../../components/Alert';
import { GlobalContext } from '../../../context/GlobalContext';


export const PhraseForm = () => {

    const { modal, setModal } = useContext(GlobalContext);

    const [message, setMessage] = useState(null);
    const [showMessage, setShowMessage] = useState(false);

    const [tags, setTags] = useState([]);
    const tagRef = useRef();
    const lang2Ref = useRef();

    const addTagHandler = () => {

        let newTag = tagRef.current.value;

        if (newTag === '') return;
        if (newTag.length >= 15) return;
        let noSpaceTag = newTag.replaceAll(' ', '');

        if (tags.length <= 2) {
            setTags(prev => [...tags, noSpaceTag]);
            tagRef.current.value = '';
            tagRef.current.focus();
        }
    };

    const deletagHandler = ({ target }) => {
        const { id } = target;

        let newTags = tags.filter(tag => {
            if (tag !== tags[id]) return tag;
        });

        setTags(prev => [...newTags]);
    };   

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            addTagHandler();
        }
    };

    const addPhraseHandler = () => {
        let lang1Input = document.getElementById('lang1Ref');

        if (lang1Input.value === '') return;

        setMessage('Frase agregada !   👏');
        lang1Input.value = '';
        lang2Ref.current.value = '';

        lang1Input.focus();
        setShowMessage(prev => !showMessage);
        addTagHandler();
    };

    const closeFormHandler = () => {
        setModal(!modal);
    };

    useEffect(() => {

        setTimeout(() => {
            setShowMessage(prev => false);            
        }, 5000);

    }, [showMessage]);


    return (
        <StyledPhraseForm>

            <div className="formContainer">
                <div className="alert">
                    {<Alert message={message} bgColor='limegreen' anim={showMessage} stay={false} />}
                </div>
                <IoCloseCircleOutline className='closeForm' onClick={closeFormHandler} />
                <p> 💡 </p>
                <input
                    type="text" autoFocus
                    id='lang1Ref'
                    ref={input => input && input.focus()}
                    className='firstInput'
                    placeholder='Español'
                />

                <input
                    type="text"
                    ref={lang2Ref}
                    placeholder='English'
                />

                <div className="tags">
                    {tags && tags.map((tag, index) => {
                        return (
                            <p key={index}> {tag}
                                <IoCloseCircleOutline
                                    id={index}
                                    className='deleteTag'
                                    onClick={deletagHandler}
                                />
                            </p>);
                    })
                    }
                </div>

                <div className="tag-container">
                    <input
                        type="text"
                        ref={tagRef}
                        placeholder='tema'
                        onKeyDown={handleKeyDown}
                    />
                    <div className="btnAddTag">
                        <button onClick={addTagHandler} ><BsBookmarkPlus /></button>
                    </div>
                </div>

                <button className='btn-add' onClick={addPhraseHandler} ><BsCheck className='add-Icon' /></button>
            </div>

        </StyledPhraseForm>
    );
};

const StyledPhraseForm = styled.div`

    padding: 4rem;    

    .alert{
        z-index: 100;
        width: 100%;
        position: absolute;
        top: -2rem;
        left: 50%;
        transform: translate(-50%,-50%);        
    }

    .closeForm{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.5rem;
        color: var(--disabled);

        :hover{
            color: var(--fontColor);
            cursor: pointer;
        }
    }

    .formContainer{
        position: relative;
        max-width: 30rem;
        background-color: var(--bgColor);
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: .1rem 1.5rem;
        gap: .5rem;
        border-radius: .5rem;

        p{
            font-size: 1.5rem;
            align-self: center;
        }

        .firstInput{
            color: var(--color5)
        }  
        
        input{
            width: 100%;
            background:  var(--clearBG);
            font-size: 1.3rem;
            padding: 1rem;
            border-radius: .5em;
            color: var(--fontColor);
            outline: none;
        
            ::placeholder{
                color: var(--disabled)
            }               
        }
    }

    .tag-container{        
        display: flex;
        align-self: end;

        border-radius: .5rem;
        gap: .3rem;        

        input{
            padding: .5rem;
            max-width: 10rem;
            font-size: 1rem;
            color: var(--color5);

            ::placeholder{
                font-size: 1rem;
            }
        }
    }

    .btnAddTag{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        

        button{
            background: none;
            color: var(--disabled);
            font-size: 1.5rem;
            cursor: pointer;
            :hover{
                color: var(--color5)
            }

        }
    }

    .btn-add{
        align-self: end;    
        background: none;
        padding: .5rem;
        cursor: pointer;
    }

    .add-Icon{
        background: var(--disabled);
        background: var(--color5);
        /* color: #ffffff33; */
        /* background: hsla(0, 0%, 100%, 0.11); */
        color: var(--bgColor);
        color: var(--fontColor);
        border-radius: 5px;
        font-size: 2rem;

        :hover{
            background: var(--color5);
            color: var(--fontColor);
        }
    }

    .tags{
        width: 100%;
        /* height: 3rem; */
        display: flex;
        align-self: start;
        gap: 1rem;
        transition: all .4s ease-in;
    
        p{
            font-size: 1.1rem;
            color: var(--disabled);
        }
    }

    .deleteTag{
        :hover{
            color: var(--fontColor);
            cursor: pointer;
        }
    }  
        
`;