import styled from 'styled-components';
import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { authContext } from '../context/authContext';
import { GlobalContext } from '../../../context/GlobalContext';
import { Alert } from '../../../components/Alert';
import { useState } from 'react';

export const LoginForm = () => {

    const { setModal } = useContext(GlobalContext);
    const { login } = useContext(authContext);
    const [error, setError] = useState();

    let emailRef = useRef();
    let passwordRef = useRef();

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(emailRef.current.value, passwordRef.current.value);
            setModal(false);
            navigate('/conjugation');
        } catch (err) {
            setError(err.code);
            // console.log(err.code);
        }
    };

    return (
        <StyledForm onSubmit={handleSubmit}>

            {error && <Alert message={error} stay={true} />}
            <form>
                <h2>User</h2>

                <input ref={emailRef} type="email" placeholder="email" required />
                <input ref={passwordRef} type="password" placeholder="password" required />

                <button>Login</button>
            </form>
        </StyledForm>
    );
};

const StyledForm = styled.div`

    align-self: center;
    
    form{
        max-width : 20rem;
        border-radius: 1rem;
        background-color: whitesmoke;


        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        padding: .5rem;
        margin: 1rem;
        color: black;

        >label{
            color: black;
            font-size: 1.5rem;
        }

        >input{
        margin: .3rem;
        padding: .4rem;
        border-radius: 1rem;
        outline: 0;
        border: 1px solid #cccccc;
        }

        >input:-webkit-autofill:hover{
            background: green;
        }

        button{
            margin: .3rem;
            padding: .3rem;
            border-radius: 1rem;
            outline: 0;
            border: 1px solid #cccccc;
            width: 100%;
            box-shadow: 0 0 8px 0 hsl(0, 0%, 50%);

            &:hover{
                background-color: orange;
                color: white;
                cursor: pointer;
            
            }  
        }    
    }    

`;


