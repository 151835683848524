import styled from 'styled-components';

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContext';

import { TbListDetails } from 'react-icons/tb';
import { RiLayout2Line } from 'react-icons/ri';
import { VscGraph } from 'react-icons/vsc';
import { BsPlus } from 'react-icons/bs';
import { SideBarItem } from '../SideBarItem';


export const SideBar = () => {

    const { isSideBarVisible, setIsSideBarVisible, activeApp } = useContext(GlobalContext);

    function activeHandler(name) {
        let active = activeApp === name ? true : false;
        return active;
    }

    const showSideBar = () => {
        setIsSideBarVisible(!isSideBarVisible);
    };

    return (
        <StyledSideBar visible={isSideBarVisible} >

            <div className="sideBarContainer">
                <SideBarItem active={activeHandler('phrases')} >
                    <Link to={'/phrases'}>
                        <TbListDetails />
                    </Link>
                    <p>Phrases</p>
                </SideBarItem>

                <SideBarItem active={activeHandler('conjugation')}>
                    <Link to={'/conjugation'}>
                        <RiLayout2Line />
                    </Link>
                    <p>Verbs</p>
                </SideBarItem>

                <SideBarItem active={activeHandler('stats')}>
                    <Link to={'/stats'}>
                        <VscGraph />
                    </Link>
                    <p>Stats</p>
                </SideBarItem>

            </div>

        </StyledSideBar>
    );
};


const StyledSideBar = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    
    background: var(--color3);
    height: 100%;

    display: flex;
    align-items: center;

    .sideBarContainer{
        display: flex;
        flex-direction: column;
        
        gap: 1rem;
        /* padding-top: 4rem; */
    }   
  

    @media all and (max-width: 629px)  {
    
    display: none;

}


`;