import styled from 'styled-components';
import { useContext } from 'react';

import { VscColorMode } from 'react-icons/vsc';
import { GlobalContext } from '../context/GlobalContext';



export const ThemeToggle = () => {
    const { theme, setTheme } = useContext(GlobalContext);

    return (
        <StyledToggle>
            <i onClick={() => { setTheme(!theme); }}><VscColorMode /></i>
        </StyledToggle>
    );
};

const StyledToggle = styled.div`
    
    cursor: pointer;

    i{
        color: var(--fontColor);
    }
  
`;
