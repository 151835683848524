import styled from 'styled-components';

import React from 'react';
import { TopBar } from '../components/Nav/TopBar';
import { Phrases } from '../features/abc/components/Phrases';
import { SearchBar } from '../features/abc/components/SearchBar';

export const Abc = () => {
    return (
        <StyledAbc>
            <div className="content">
                <SearchBar />
                <Phrases />
            </div>
            <TopBar />
        </StyledAbc>
    );
};


const StyledAbc = styled.div`
    
    /* background: green; */
    padding: 3rem .5rem ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 1rem;

    .content{
        max-width: 30rem;
    }

`;